import React, { useState, useMemo, useEffect } from 'react';
import BackArrow from '../../ui/BackArrow/BackArrow';
import FormContainer from '../../ui/Form/FormContainer/FormContainer';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import FormInput from '../../ui/Form/FormInput/FormInput';
import FormArea from '../../ui/Form/FormArea/FormArea';
import FormSelect from '../../ui/Form/FormSelect/FormSelect';
import AsyncSelect from '../../ui/Form/FormSelect/FormAsyncSelect';
import SectionHeader from '../../ui/SectionHeader/SectionHeader';
import SubmitButton from '../../ui/Button/SubmitButton';
import OriginModal from '../../pages/ModalContents/OriginModal';
import DivisionModal from '../../pages/ModalContents/DivisionModal';
import CompanyNameModal from '../../pages/ModalContents/CompanyNameModal';
import CountryModal from '../../pages/ModalContents/CountryModal';
import LeadInfoModal from '../../pages/ModalContents/LeadInfoModal';
import PoiModal from '../../pages/ModalContents/PoiModal';
import {
    countries,
    beneluxCountries,
    phoneRegExp
} from '../../utilities/consts';

const notRequiredSchema = yup.object().shape({
    value: yup.string()
});

const originMsg = 'Please select an origin.';
const originSchema = yup
    .object()
    .shape({
        value: yup.string().required(originMsg)
    })
    .required(originMsg)
    .nullable(originMsg);

const companyMsg = 'Please enter a company name.';
const companySchema = yup
    .object()
    .shape({
        value: yup.string().required(companyMsg)
    })
    .required(companyMsg)
    .nullable(companyMsg);

const divisionMsg = 'Please select a division.';
const divisionSchema = yup
    .object()
    .shape({
        value: yup.string().required(divisionMsg)
    })
    .required(divisionMsg)
    .nullable(divisionMsg);

const gpaMsg = 'This field is required, please select "Yes" or "No".';
const gpaSchema = yup
    .object()
    .shape({
        value: yup.boolean().required(gpaMsg)
    })
    .required(gpaMsg)
    .nullable(gpaMsg);

const countryMsg = 'Please enter a country.';
const countrySchema = yup
    .object()
    .shape({
        value: yup.string().required(countryMsg)
    })
    .required(countryMsg)
    .nullable(countryMsg);

export default function CreateLeadComponent({
    origins,
    salutations,
    gpaAnswers,
    divisions,
    loadOptions,
    handleHelp,
    companiesLoading,
    onSubmit
}) {
    const [isCountryVisible, setIsCountryVisible] = useState(false);

    const schema = useMemo(() => {
        const object = {
            origin: originSchema,
            company_name: companySchema,
            contact_title: notRequiredSchema,
            contact_first_name: yup
                .string()
                .matches(/^([^0-9]*)$/, 'The name must not contain numbers')
                .required('Please enter a first name.'),
            contact_last_name: yup
                .string()
                .min(2, `This field must have at least 2 characters`)
                .matches(/^([^0-9]*)$/, 'The name must not contain numbers')
                .required('Please enter a last name.'),
            contact_email: yup
                .string()
                .email(
                    'Email address must include @ symbol and TLD such as .com/.co.uk'
                )
                .required('This field is required.'),
            contact_number: yup
                .string()
                .matches(phoneRegExp, 'This phone number is not valid'),
            product_of_interest: yup
                .string()
                .min(2, 'Please enter more information')
                .max(255, 'You have reached the maximum amount of characters')
                .required(
                    'Please provide the product(s) and solutions of interest.'
                ),
            additional_information: yup
                .string()
                .max(1000, 'You have reached the maximum amount of characters'),
            division: divisionSchema,
            gpa: gpaSchema
        };
        if (isCountryVisible) {
            object.country = countrySchema;
        }
        return yup.object().shape(object);
    }, [isCountryVisible]);

    // The methods used for the form
    const methods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema)
    });

    // Watch the value of division to trigger country field
    const division = methods.watch('division', false);

    // Updates state which in turn updates the yup schema object
    useEffect(() => {
        const currentCountry = methods.getValues().country;
        const currentDivision = division?.value;

        setIsCountryVisible(
            [
                'Assurance UK and Europe (Iberia & Nordics)',
                'Assurance Benelux (Fox-IT)'
            ].includes(currentDivision)
        );

        const shouldResetAssuranceUK =
            currentDivision === 'Assurance UK and Europe (Iberia & Nordics)' &&
            !countries.some((item) => item.value === currentCountry?.value);

        const shouldResetAssuranceBenelux =
            currentDivision === 'Assurance Benelux (Fox-IT)' &&
            !beneluxCountries.some(
                (item) => item.value === currentCountry?.value
            );

        if (shouldResetAssuranceUK || shouldResetAssuranceBenelux) {
            methods.setValue('country', null, {
                shouldValidate: true
            });
        }
    }, [division]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="mx-auto flex flex-col items-center px-4 md:px-thirty md:mb-10 xl:mt-2 xl:px-forty xl:items-start xl:flex-row  xl:gap-thirty">
                    <div className="m-0 p-0 w-full xl:h-full">
                        <BackArrow
                            destination={'/'}
                            header="Create a new referral"
                        />
                        <FormContainer wrapperTag="origin">
                            <SectionHeader
                                header="How did this referral originate?"
                                tooltip="origin"
                                handleHelp={handleHelp}
                                component={<OriginModal />}
                                isRequired
                            />
                            <FormSelect
                                name="origin"
                                id="origin"
                                opts={origins}
                                placeholder="Select..."
                                dataCy="origin"
                                dataTestId="origin"
                            />
                        </FormContainer>
                        <FormContainer wrapperTag="customer-details">
                            <SectionHeader
                                header="Customer details"
                                headingType="main"
                            />
                            <AsyncSelect
                                label="Company name"
                                tooltip="company_name"
                                handleHelp={handleHelp}
                                component={<CompanyNameModal />}
                                name="company_name"
                                id="company_name"
                                placeholder="Start typing..."
                                isClearable
                                loadOptions={loadOptions}
                                maxLength={255}
                                hasCount={true}
                                hideIcons
                                dataCy="company"
                                dataTestId="company"
                                isRequired
                                loading={companiesLoading}
                            />
                            <FormSelect
                                label="Salutation"
                                name="contact_title"
                                id="contact_title"
                                opts={salutations}
                                placeholder="Select..."
                                dataCy="salutation"
                                dataTestId="salutation"
                            />
                            <FormInput
                                label="First name"
                                type="text"
                                name="contact_first_name"
                                id="contact_first_name"
                                placeholder="John"
                                maxLength={40}
                                hasCount={true}
                                dataCy="first-name"
                                dataTestId="first-name"
                                isRequired
                            />
                            <FormInput
                                label="Last name"
                                type="text"
                                name="contact_last_name"
                                id="contact_last_name"
                                placeholder="Smith"
                                maxLength={80}
                                hasCount={true}
                                dataCy="last-name"
                                dataTestId="last-name"
                                isRequired
                            />
                            <FormInput
                                label="Email address"
                                type="text"
                                name="contact_email"
                                id="contact_email"
                                placeholder="Example@example.com"
                                maxLength={80}
                                hasCount={true}
                                dataCy="email"
                                dataTestId="email"
                                isRequired
                            />
                            <FormInput
                                label="Phone number"
                                type="text"
                                name="contact_number"
                                id="contact_number"
                                placeholder="07958745712"
                                maxLength={20}
                                hasCount={true}
                                dataCy="phone"
                                dataTestId="phone"
                            />
                        </FormContainer>
                    </div>
                    <div className="w-full xl:h-full">
                        <div className="hidden xl:block xl:h-fortynine"></div>
                        <FormContainer wrapperTag="division">
                            <SectionHeader
                                header="Which division are you submitting this to?"
                                tooltip="division"
                                handleHelp={handleHelp}
                                component={<DivisionModal />}
                                isRequired
                            />
                            <FormSelect
                                name="division"
                                id="division"
                                placeholder="Select..."
                                opts={divisions}
                                dataCy="division"
                                dataTestId="division"
                            />
                        </FormContainer>
                        {isCountryVisible ? (
                            <FormContainer wrapperTag="country">
                                <SectionHeader
                                    header="Where is the company located?"
                                    tooltip="country"
                                    handleHelp={handleHelp}
                                    component={<CountryModal />}
                                    isRequired
                                />
                                <FormSelect
                                    name="country"
                                    id="country"
                                    placeholder="Select..."
                                    opts={
                                        division?.value ===
                                        'Assurance UK and Europe (Iberia & Nordics)'
                                            ? countries
                                            : beneluxCountries
                                    }
                                    dataCy="country"
                                    dataTestId="country"
                                />
                            </FormContainer>
                        ) : null}
                        <FormContainer wrapperTag="lead-details">
                            <SectionHeader
                                header="Lead details"
                                headingType="main"
                            />
                            <FormArea
                                label="Products / Solutions of Interest"
                                tooltip="product_of_interest"
                                handleHelp={handleHelp}
                                component={<PoiModal />}
                                type="text"
                                name="product_of_interest"
                                id="product_of_interest"
                                rows={4}
                                maxLength={255}
                                hasCount={true}
                                dataCy="poi"
                                dataTestId="poi"
                                isRequired
                            />
                            <FormArea
                                label="Additional Information"
                                tooltip="additional_information"
                                handleHelp={handleHelp}
                                component={<LeadInfoModal />}
                                type="text"
                                name="additional_information"
                                id="additional_information"
                                rows={4}
                                maxLength={1000}
                                hasCount={true}
                                dataCy="information"
                                dataTestId="information"
                            />
                            <SectionHeader header="Product Discovery" />
                            <FormSelect
                                label="Did Our Global Portfolio help you discover this opportunity?"
                                name="gpa"
                                id="gpa"
                                placeholder="Select..."
                                opts={gpaAnswers}
                                dataCy="gpa"
                                dataTestId="gpa"
                                isRequired
                            />
                        </FormContainer>
                        <div className="mt-twentyfive mb-twenty w-full max-w-7xl xl:max-w-8xl sm:mb-0 xl:mt-thirty">
                            <SubmitButton
                                label="Submit referral"
                                disabled={!methods.formState.isValid}
                                dataCy="new-lead-computer-submit-button"
                                dataTestId="new-lead-computer-submit-button"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}
