import { useContext } from 'react';
import { Switch } from '@headlessui/react';
import { ThemeContext } from '../../context/ThemeContext';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Toggle() {
    const { theme, setTheme } = useContext(ThemeContext);
    const checked = theme === 'dark';
    const clr = theme !== 'dark' ? 'black' : 'white';

    return (
        <div className="flex px-4 py-3 gap-2">
            <Switch
                data-cy="navbar-darkmode-switch"
                checked={checked}
                onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                className={classNames(
                    checked ? 'bg-gray-400' : 'bg-black',
                    'relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={classNames(
                        checked ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
            <div className="flex flex-col justify-center">
                <p className={`text-${clr} font-light text-sm`}>
                    {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
                </p>
            </div>
        </div>
    );
}
