import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';
import useTailwind, { TailwindProps } from '../tailwind/useTailwind';
import usePanel from './usePanel';

interface PanelFooterProps extends TailwindProps {
    className?: string;
    ['data-cy']?: string;
    ['data-testid']?: string;
    style?: CSSProperties;
}

function PanelFooter({
    className,
    ['data-cy']: dataCy,
    ['data-testid']: dataTestId,
    tailwindLayout = 'flex justify-content items-end place-content-between gap-4',
    tailwindSize = 'mt-8 md:mt-twenty xl:mt-thirty',
    style,
    children,
    ...restProps
}: PropsWithChildren<PanelFooterProps>) {
    const { loading } = usePanel();
    const cssClass = useTailwind(
        {
            ...restProps,
            tailwindLayout: classNames(loading ? 'z-10' : null, tailwindLayout),
            tailwindSize
        },
        className
    );

    return (
        <div
            className={cssClass}
            data-cy={dataCy}
            data-testid={dataTestId}
            style={style}
        >
            {children}
        </div>
    );
}

export default PanelFooter;
export type { PanelFooterProps };
