import React from 'react';
import Welcome from '../../ui/Welcome/Welcome';
import ReferralsCard from '../../ui/ReferralsCard/ReferralsCard';
import HelpCard from '../../ui/HelpCard/HelpCard';
import TotalCard from '../../ui/TotalCard/TotalCard';
import LeadCard from '../../ui/LeadCard/LeadCard';
import RewardsCard from '../../ui/RewardsCard/RewardsCard';
import StampCard from '../../ui/StampCard/StampCard';
import useTailwind from '../../uiLib/tailwind/useTailwind';
import Divider from '../../ui/Divider/Divider';

function DashboardLayout({
    currency,
    theme,
    dashboardData,
    multiData,
    user,
    loadingDashData
}) {
    const tailwindLayout = 'flex flex-col xl:flex-row xl:justify-center gap-5';
    const tailwindSize = 'md:mt-4 xl:m-12 xl:pt-0 p-5 xl:p-0 ';
    const cssClass = useTailwind({
        tailwindLayout,
        tailwindSize
    });

    const gridGap = 'gap-3 md:gap-4 xl:gap-5';

    return (
        <div className={cssClass}>
            <div className={`flex-2 flex flex-col ${gridGap}`}>
                <Welcome user={user} />
                <LeadCard theme={theme} />
                <div
                    className={`flex-1 grid grid-cols-1 md:grid-cols-2 ${gridGap}`}
                >
                    <RewardsCard
                        amount={dashboardData?.total}
                        currency={currency}
                        loadingDashData={loadingDashData}
                    />
                    <ReferralsCard
                        company={dashboardData?.latest_referral?.company_name}
                        state={dashboardData?.latest_referral?.state}
                        loadingDashData={loadingDashData}
                    />
                </div>
            </div>

            <Divider className="hidden xl:block" />

            <div
                className={`flex-1 grid grid-cols-1 md:grid-cols-2 xl:flex flex-col ${gridGap}`}
            >
                <HelpCard tailwindLayout="hidden xl:flex flex-col justify-center" />
                <StampCard
                    tailwindLayout="hidden md:flex flex-col"
                    data={multiData}
                    user={user}
                />
                <div className={`flex flex-col ${gridGap}`}>
                    <HelpCard tailwindLayout="flex-1 flex flex-col justify-center xl:hidden" />
                    <TotalCard
                        tailwindLayout="flex-1 flex flex-col justify-center"
                        number={dashboardData?.total_referrals}
                    />
                </div>
            </div>
        </div>
    );
}

export default DashboardLayout;
