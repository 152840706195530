import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import { useHttpContext } from '../../context/HttpContext';
import useRequest from '../../hooks/useRequest';
import FullscreenSpinner from '../../ui/FullscreenSpinner/FullscreenSpinner';
import ReferralsComponent from '../../components/ReferralsPage/ReferralsComponent';
import { stateOptions } from '../../utilities/consts';

const ReferralsContainer = () => {
    const { fetchReferrals } = useHttpContext();

    const [filterOptions, setFilterOptions] = useState();

    const [currentFilter, setCurrentFilter] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    // Get our data with useRequest hook
    const {
        send: allReferrals,
        response: allReferralsRes,
        loading: allReferralsLoading
    } = useRequest(fetchReferrals);

    const {
        send: requestReferrals,
        response: referrals,
        loading: referralsLoading
    } = useRequest(fetchReferrals);

    const isLoading = useMemo(
        () => allReferralsLoading || !filterOptions,
        [allReferralsLoading, filterOptions]
    );

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    // Update current filter to select value
    const handleFilterChange = useCallback((value) => {
        if (value.value !== currentFilter) {
            setCurrentPage(1);
            setCurrentFilter(value.value !== 'all' ? value.value : undefined);
        }
    });

    // Request referrals count
    useEffect(() => {
        allReferrals([undefined, undefined, undefined, undefined, true]);
    }, []);

    // Set select options with counts
    useEffect(() => {
        if (allReferralsRes) {
            const {
                new: newCount,
                on_hold,
                in_progress,
                qualified,
                disqualified,
                proposed,
                lost,
                won,
                invoiced
            } = allReferralsRes.state_counts ?? {
                new: 0,
                on_hold: 0,
                in_progress: 0,
                qualified: 0,
                disqualified: 0,
                proposed: 0,
                lost: 0,
                won: 0,
                invoiced: 0
            };

            const nextFilterOptions = _.cloneDeep(stateOptions).map(
                (option) => {
                    switch (option.value) {
                        case 'all': {
                            const total =
                                newCount +
                                on_hold +
                                in_progress +
                                qualified +
                                disqualified +
                                proposed +
                                lost +
                                won +
                                invoiced;
                            option.label += ` (${total})`;
                            break;
                        }
                        case 'new':
                            option.label += ` (${newCount})`;
                            break;
                        case 'on_hold':
                            option.label += ` (${on_hold})`;
                            break;
                        case 'in_progress':
                            option.label += ` (${in_progress})`;
                            break;
                        case 'qualified':
                            option.label += ` (${qualified})`;
                            break;
                        case 'disqualified':
                            option.label += ` (${disqualified})`;
                            break;
                        case 'proposed':
                            option.label += ` (${proposed})`;
                            break;
                        case 'lost':
                            option.label += ` (${lost})`;
                            break;
                        case 'won':
                            option.label += ` (${won})`;
                            break;
                        case 'invoiced':
                            option.label += ` (${invoiced})`;
                            break;
                    }

                    return option;
                }
            );
            setFilterOptions(nextFilterOptions);
        }
    }, [allReferralsRes]);

    // Request current page
    useEffect(() => {
        requestReferrals([currentPage, currentFilter]);
    }, [currentPage, currentFilter]);

    return isLoading ? (
        <FullscreenSpinner />
    ) : (
        <ReferralsComponent
            referrals={referrals?.results}
            onPageChange={handlePageChange}
            totalPages={referrals?.total_pages}
            totalReferrals={referrals?.count}
            currentPage={currentPage}
            onFilterChange={handleFilterChange}
            filterOptions={filterOptions}
            loading={referralsLoading}
        />
    );
};

export default ReferralsContainer;
