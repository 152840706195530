export default function currencySymbol(curr) {
    const x = curr?.toLowerCase();
    let sym = '';

    switch (x) {
        case 'gbp':
            sym = '£';
            break;
        case 'aud':
            sym = 'AU$';
            break;
        case 'sgd':
            sym = 'SG$';
            break;
        case 'jpy':
            sym = '¥';
            break;
        case 'usd':
            sym = '$';
            break;
        case 'cad':
            sym = 'CA$';
            break;
        case 'eur':
            sym = '€';
            break;
        case 'dkk':
            sym = 'kr';
            break;
    }
    return sym;
}
