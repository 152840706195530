import React, { createContext } from 'react';
import useShowModal from '../hooks/useShowModal';

const ShowModalContext = createContext();

function ShowModalProvider({ children }) {
    const {
        showModal,
        setShowModal,
        modalContent,
        setModalContent,
        closeOnBackdrop,
        setCloseOnBackdrop,
        showCross,
        setShowCross
    } = useShowModal();

    return (
        <ShowModalContext.Provider
            value={{
                showModal,
                setShowModal,
                modalContent,
                setModalContent,
                closeOnBackdrop,
                setCloseOnBackdrop,
                showCross,
                setShowCross
            }}
        >
            {children}
        </ShowModalContext.Provider>
    );
}

export { ShowModalContext, ShowModalProvider };
