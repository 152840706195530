/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @function classNames
 * @description pass multiple class names which are joined into a single string
 * @param  {...any} classes
 */
const classNames = (...classes: any): string => {
    return classes.filter(Boolean).join(' ');
};

export default classNames;
