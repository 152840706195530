export const userDivisionOptions = [
    { label: 'Assurance UK', value: 'Assurance UK' },
    { label: 'Assurance Iberia', value: 'Assurance Iberia' },
    { label: 'Assurance Nordics', value: 'Assurance Nordics' },
    { label: 'Assurance NA', value: 'Assurance NA' },
    { label: 'Assurance APAC', value: 'Assurance APAC' },
    {
        label: 'Assurance Benelux (Fox-IT)',
        value: 'Assurance Benelux (Fox-IT)'
    },
    { label: 'Software Resilience UK', value: 'Software Resilience UK' },
    { label: 'Software Resilience NA', value: 'Software Resilience NA' },
    { label: 'Software Resilience EU', value: 'Software Resilience EU' },
    { label: 'Group Functions', value: 'Group Functions' }
];

export const currencies = [
    { label: 'GBP', value: 'GBP', symbol: '£' },
    { label: 'AUD', value: 'AUD', symbol: 'AU$' },
    { label: 'SGD', value: 'SGD', symbol: 'SG$' },
    { label: 'JPY', value: 'JPY', symbol: '¥' },
    { label: 'USD', value: 'USD', symbol: '$' },
    { label: 'CAD', value: 'CAD', symbol: 'CA$' },
    { label: 'EUR', value: 'EUR', symbol: '€' },
    { label: 'DKK', value: 'DKK', symbol: 'kr' }
];

export const newLeadOrigins = [
    {
        name: 'origin',
        label: 'Delivered customer work, and I identified & discussed a new opportunity, outside existing scope',
        value: 'delivered_work'
    },
    {
        name: 'origin',
        label: 'New business lead from my professional network',
        value: 'professional_network'
    },
    {
        name: 'origin',
        label: 'New business lead from my personal network',
        value: 'personal_network'
    },
    {
        name: 'origin',
        label: "Sales: Conversation identified need for the other division's services outside my normal sales remit",
        value: 'sales'
    },
    {
        name: 'origin',
        label: 'Other - (Please detail origin in Lead Information)',
        value: 'other'
    }
];

export const salutations = [
    {
        name: 'contact_salutation',
        label: 'Mr.',
        value: 'Mr.'
    },
    {
        name: 'contact_salutation',
        label: 'Ms.',
        value: 'Ms.'
    },
    {
        name: 'contact_salutation',
        label: 'Mrs.',
        value: 'Mrs.'
    },
    {
        name: 'contact_salutation',
        label: 'Dr.',
        value: 'Dr.'
    },
    {
        name: 'contact_salutation',
        label: 'Prof.',
        value: 'Prof.'
    },
    {
        name: 'contact_salutation',
        label: 'Unknown.',
        value: ''
    }
];

export const gpaAnswers = [
    {
        name: 'gpa',
        label: 'Yes',
        value: true
    },
    {
        name: 'gpa',
        label: 'No',
        value: false
    }
];

export const countries = [
    {
        name: 'country',
        label: 'Austria',
        value: 'Austria'
    },
    {
        name: 'country',
        label: 'Denmark',
        value: 'Denmark'
    },
    {
        name: 'country',
        label: 'Germany',
        value: 'Germany'
    },
    {
        name: 'country',
        label: 'Portugal',
        value: 'Portugal'
    },
    {
        name: 'country',
        label: 'Spain',
        value: 'Spain'
    },
    {
        name: 'country',
        label: 'Sweden',
        value: 'Sweden'
    },
    {
        name: 'country',
        label: 'Switzerland',
        value: 'Switzerland'
    },
    {
        name: 'country',
        label: 'UK',
        value: 'UK'
    },
    {
        name: 'country',
        label: 'Other',
        value: 'Other'
    }
];

export const beneluxCountries = [
    {
        name: 'country',
        label: 'Netherlands, Belgium & Luxembourg',
        value: 'Netherlands, Belgium & Luxembourg'
    },
    {
        name: 'country',
        label: 'Other',
        value: 'Other'
    }
];

export const divisions = [
    {
        name: 'division',
        label: 'Assurance UK and Europe (Iberia & Nordics)',
        value: 'Assurance UK and Europe (Iberia & Nordics)'
    },
    { name: 'division', label: 'Assurance NA', value: 'Assurance NA' },
    { name: 'division', label: 'Assurance APAC', value: 'Assurance APAC' },
    {
        name: 'division',
        label: 'Assurance Benelux (Fox-IT)',
        value: 'Assurance Benelux (Fox-IT)'
    },
    {
        name: 'division',
        label: 'Software Resilience UK',
        value: 'Software Resilience UK'
    },
    {
        name: 'division',
        label: 'Software Resilience NA',
        value: 'Software Resilience NA'
    },
    {
        name: 'division',
        label: 'Software Resilience EU',
        value: 'Software Resilience EU'
    }
];

export const phoneRegExp =
    /(\+|00|0)*(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$|^\s*$/; // added |^\s*$ to the end.

export const stateOptions = [
    { name: 'all', value: 'all', label: 'All' },
    { name: 'new', value: 'new', label: 'New' },
    { name: 'on_hold', value: 'on_hold', label: 'On Hold' },
    { name: 'in_progress', value: 'in_progress', label: 'In Progress' },
    { name: 'qualified', value: 'qualified', label: 'Qualified' },
    { name: 'proposed', value: 'proposed', label: 'Proposed' },
    { name: 'disqualified', value: 'disqualified', label: 'Disqualified' },
    { name: 'lost', value: 'lost', label: 'Lost' },
    { name: 'won', value: 'won', label: 'Won' },
    { name: 'invoiced', value: 'invoiced', label: 'Invoiced' }
];
