/**
 * React imports
 */
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ dataCy, dataTestId, isResponsive = false }) => {
    const defaultStyles = 'font-sans font-light tracking-wider';

    return (
        <div className="flex justify-between items-center text-white">
            <Link to="/" className="text-xl" data-cy="navbar-logo-link">
                <div className="flex items-center gap-3">
                    <img
                        className="h-10 lg:h-9"
                        src="/images/ncc-white-logo.svg"
                        alt="Logo"
                        data-cy={dataCy}
                        data-testid={dataTestId}
                    />
                    <p
                        className={
                            isResponsive
                                ? `${defaultStyles} text-twenty lg:text-thirtytwo`
                                : `${defaultStyles} text-twenty`
                        }
                        data-cy="navbar-logo-text"
                        data-testid="navbar-logo-text"
                    >
                        Referral App
                    </p>
                </div>
            </Link>
        </div>
    );
};

export default Logo;
