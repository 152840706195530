import React, { createContext } from 'react';
import useIsAuthenticated from '../hooks/useIsAuthenticated';

const IsAuthenticatedContext = createContext();

function IsAuthenticatedProvider({ children }) {
    const { isAuthenticated, setIsAuthenticated } = useIsAuthenticated();

    return (
        <IsAuthenticatedContext.Provider
            value={{ isAuthenticated, setIsAuthenticated }}
        >
            {children}
        </IsAuthenticatedContext.Provider>
    );
}

export { IsAuthenticatedContext, IsAuthenticatedProvider };
