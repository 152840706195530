/**
 * React imports
 */
import React from 'react';

/**
 * Component imports
 */
import Logo from '../../ui/Logo/Logo';
import Nav from '../../components/nav/Nav';

const Navbar = (): React.ReactElement => {
    return (
        <header
            className="relative px-6 h-smnav sm:h-lgnav sm:py-5 bg-primary flex flex-wrap items-center z-50 dark:bg-darknavbar"
            data-cy="navbar-header"
        >
            <Logo dataCy="navbar-logo" dataTestId="navbar-logo" />
            <div className="flex ml-auto">
                <Nav />
            </div>
        </header>
    );
};

export default Navbar;
