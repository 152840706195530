import React, { useCallback } from 'react';
import { Button } from '../Button/Button';
import { useHistory } from 'react-router-dom';
import Panel from '../../uiLib/panel';
import ReferralDetailProgressBar from '../ReferralDetailCard/ReferralDetailProgressBar';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';
import ReferralsCompanySection from './ReferralsCompanySection';
import NoReferralsSection from './NoReferralsSections';

interface ReferralsCardProps extends TailwindProps {
    company?: string | null;
    state: string | null;
    loadingDashData: boolean;
}

function ReferralsCard({
    company,
    state,
    loadingDashData,
    ...restProps
}: ReferralsCardProps) {
    const history = useHistory();

    const labelClasses =
        'hidden md:block text-sixteen font-light text-fontprimary dark:text-darkmodetext';
    const valueClasses =
        'text-fourteen sm:text-sixteen font-semibold text-fontprimary dark:text-darkmodetext';

    const viewAll = useCallback(() => {
        history.push('/referrals');
    }, []);

    return (
        <Panel
            tailwindPosition="relative"
            loading={loadingDashData}
            {...restProps}
        >
            <img
                src="/images/performance-overview.svg"
                alt="Latest referral"
                className="absolute bottom-4 xl:bottom-5 right-2 xl:right-5 h-12 xl:h-16"
                data-cy="latest-referral-image"
                data-testid="latest-referral-image"
            />
            <Panel.Header
                icon={
                    <img
                        src="/images/magnifier.svg"
                        alt="Latest referral"
                        style={{ filter: 'invert(100%)' }}
                        data-cy="latest-referral-icon"
                        data-testid="latest-referral-icon"
                    />
                }
                title="Latest Referral"
            />
            <Panel.Body tailwindLayout="flex-1 flex flex-col">
                <Panel
                    variant="secondary"
                    tailwindLayout="flex-1 flex flex-col justify-center xl:mb-2"
                    tailwindSize="px-6 py-3 md:py-6"
                >
                    <Panel.Body className="flex flex-col gap-2">
                        <h4 className="sr-only">Status</h4>
                        {!state || !company ? (
                            <NoReferralsSection />
                        ) : (
                            <>
                                <ReferralsCompanySection
                                    valueClasses={valueClasses}
                                    labelClasses={labelClasses}
                                    state={state}
                                    company={company}
                                />
                                <ReferralDetailProgressBar state={state} />
                            </>
                        )}
                    </Panel.Body>
                </Panel>
            </Panel.Body>
            <Panel.Footer>
                <Button
                    label="View all referrals"
                    variant="outlined"
                    dataCy="latest-referral-button"
                    dataTestId="latest-referral-button"
                    onClick={viewAll}
                />
            </Panel.Footer>
        </Panel>
    );
}

export default ReferralsCard;
export type { ReferralsCardProps };
