/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import useMediaQuery, { BREAKPOINTS } from '../../uiLib/utils/useMediaQuery';

function BackgroundImage() {
    const { theme } = useContext(ThemeContext);
    const isDesktop = useMediaQuery(BREAKPOINTS.desktop);

    const whiteLayer = isDesktop
        ? '100% 100%, 42% 100%, 67% 0, 100% 0'
        : '0 100%, 100% 100%, 100% 63%, 0 84%';

    return (
        <div
            className="absolute top-0 right-0 bottom-0 left-0 bg-cover bg-center h-full w-full"
            style={{
                zIndex: '0',
                backgroundColor: theme === 'light' ? 'white' : '#333333',
                clipPath: `polygon(${whiteLayer})`
            }}
        />
    );
}

function GradientBackground({ children, isLoginPage }) {
    const { theme } = useContext(ThemeContext);

    const backgroundStyle = useMemo(() => {
        if (theme === 'light') {
            return {
                background:
                    'linear-gradient(183deg, rgba(37,45,124,1) 35%, rgba(255,255,255,1) 100%)'
            };
        } else {
            return {
                background: '#222222'
            };
        }
    }, [theme]);

    return (
        <div
            className="w-screen h-screen relative bg-cover bg-center"
            style={backgroundStyle}
        >
            <BackgroundImage />
            <div style={{ position: 'relative', zIndex: '10', height: '100%' }}>
                {children}
            </div>

            {isLoginPage && (
                <div className="absolute bottom-2 right-4 text-black text-xs tracking-tight md:text-sm dark:text-darkmodetext">
                    Client Version: {process.env.REACT_APP_VERSION}
                </div>
            )}
        </div>
    );
}

export default GradientBackground;
