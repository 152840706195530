import { createContext, PropsWithChildren } from 'react';

interface PanelContextValue {
    collapsed: boolean | undefined;
    loading: boolean | undefined;
}

const PanelContext = createContext<PanelContextValue | undefined>(undefined);

interface PanelContextProviderProps {
    value: PanelContextValue;
}

function PanelContextProvider({
    value,
    children
}: PropsWithChildren<PanelContextProviderProps>) {
    return (
        <PanelContext.Provider value={value}>{children}</PanelContext.Provider>
    );
}

export default PanelContextProvider;
export { PanelContext };
export type { PanelContextValue, PanelContextProviderProps };
