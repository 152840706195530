import HeadingCard from './HeadingCard';

interface CompanyCardProps {
    company: string | undefined;
}

function CompanyCard({ company }: CompanyCardProps) {
    return (
        <HeadingCard
            label="Company:"
            value={<div className="truncate">{company}</div>}
            title={company}
            testTag="company-card"
        />
    );
}

export default CompanyCard;
