/**
 * React imports
 */
import React, { useState, useEffect, useContext } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { IsAuthenticatedContext } from '../../context/IsAuthenticatedContext';
import axios from 'axios';

/**
 * Component imports
 */
import FullscreenSpinner from '../../ui/FullscreenSpinner/FullscreenSpinner';
import { AccessTokenContext } from '../../context/AccessTokenContext';
import { UserContext } from '../../context/UserContext';
// import useRequest from '../../hooks/useRequest';

const Auth = (): React.ReactElement => {
    const { isAuthenticated, setIsAuthenticated } = useContext(
        IsAuthenticatedContext
    );
    const { setAccessToken } = useContext(AccessTokenContext);
    const { setUser } = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();

    // Retrieve OAuth access code
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const authCode = params.get('code');

    async function fetchUser(token) {
        const source = axios.CancelToken.source();

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_AUTH_USER_PATH}`,
            {
                headers: {
                    Authorization: token
                }
            }
        );
        setUser(data);
        localStorage.setItem('loggedInUser', JSON.stringify(data));
        const loggedIn = localStorage.getItem('loggedInUser');
        const localUser = JSON.parse(loggedIn as string);
        setUser(localUser);

        if (data.department === null || data.currency === null) {
            return history.push('/divisions');
        }

        return () => {
            source.cancel();
        };
    }

    // Retrieve OAuth access token and set in localStorage
    useEffect(() => {
        if (authCode) {
            const getAccessToken = async (authCode: string) => {
                const url = process.env.REACT_APP_TOKEN_PATH;
                const { data } = await axios.get(url as string, {
                    headers: { Authorization: authCode }
                });
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                setIsAuthenticated(true);
                setAccessToken(data.access_token);
                setRedirect(true);
                fetchUser(data.access_token);
            };

            getAccessToken(authCode);
        }
    }, []);

    return (
        <>
            {!redirect && !isAuthenticated ? (
                <FullscreenSpinner loadingText="Logging you in..." />
            ) : (
                <Redirect to="/" />
            )}
        </>
    );
};

export default Auth;
