import { useState, useEffect } from 'react';

function useWindowSize() {
    // The initial state with the undefined width and height
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        // Handler to call on window's resizing
        function handleResize() {
            // Set width and heigh to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);

        // Call handler to update state with initial window size
        handleResize();

        // Remove listener fo clean up
        return () => window.removeEventListener('resize', handleResize);
        // No dependancy array to only run on intialisation.
    }, []);

    return windowSize;
}

export default useWindowSize;
