import PageWrapper from '../../ui/PageWrapper/PageWrapper';
import SectionHeader from '../../ui/SectionHeader/SectionHeader';
import StateSelect from '../../ui/Form/FormSelect/StateSelect';
import Pagination from '../../ui/Pagination/Pagination';
import ReferralDetailsCard from '../../ui/ReferralDetailCard/ReferralDetailsCard';
import Spinner from '../../ui/spinner/Spinner';

import './ReferralsComponent.css';

const ReferralsComponent = ({
    referrals = [],
    onPageChange,
    totalPages,
    totalReferrals,
    currentPage,
    loading,
    filterOptions,
    onFilterChange
}) => {
    return (
        <PageWrapper destination="/" header="All Referrals">
            <div className="flex flex-col w-full h-full xl:flex-row">
                <div className="mt-4 md:mt-0 xl:w-1/3 flex flex-col xl:place-content-between xl:h-x">
                    <div>
                        <div className="mb-twenty">
                            <SectionHeader
                                header="Current state"
                                headingType="main"
                            />
                        </div>
                        <div className="mb-2 md:mb-6 xl:mb-0">
                            <StateSelect
                                defaultOption={filterOptions[0]}
                                options={filterOptions}
                                handleSelect={onFilterChange}
                                dataCy="referral-filter-select"
                                dataTestId="referral-filter-select"
                            />
                        </div>
                    </div>
                    <div className="hidden xl:flex xl:flex-col xl:items-center xl:justify-center xl:mb-24 xl:px-16">
                        <img
                            src="/images/performance-overview.svg"
                            alt="referrals image"
                            data-cy="referrals-page-image"
                            data-testid="referrals-page-image"
                            className="h-max"
                        />
                    </div>
                </div>
                <div
                    className="hidden xl:mb-20 xl:block xl:border xl:border-1 xl:border-bordergrey xl:mx-thirty"
                    data-cy="referrals-page-separator"
                    data-testid="referrals-page-separator"
                />
                <div className="xl:w-2/3 flex flex-col">
                    <div className="referrals-component__list relative">
                        <div>
                            {!referrals.length ? (
                                <div>
                                    <p>No results</p>
                                </div>
                            ) : (
                                referrals.map((referral) => {
                                    return (
                                        <div
                                            className="mb-3 md:mb-6 xl:mb-7"
                                            key={referral?.id}
                                        >
                                            <ReferralDetailsCard
                                                disabled={loading}
                                                data={referral}
                                            />
                                        </div>
                                    );
                                })
                            )}
                        </div>
                        {loading && (
                            <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
                                <Spinner size={12} />
                            </div>
                        )}
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={onPageChange}
                        totalCount={totalReferrals}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

export default ReferralsComponent;
