import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Panel from '../../uiLib/panel';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';

import cashConverter from '../../utilities/moneyFormatter';
import { Button } from '../Button/Button';

interface RewardsCardProps extends TailwindProps {
    amount: string;
    currency: string;
    loadingDashData: boolean;
}

function RewardsCard({ amount, currency, loadingDashData, ...restProps }) {
    const history = useHistory();

    const viewAll = useCallback(() => {
        history.push('/rewards');
    }, []);

    return (
        <Panel
            tailwindPosition="relative"
            loading={loadingDashData}
            {...restProps}
        >
            <img
                src="/images/gifts.svg"
                alt="My Rewards"
                className="absolute bottom-4 xl:bottom-5 right-4 xl:right-8 h-12 xl:h-16"
                data-cy="my-rewards-image"
                data-testid="my-rewards-image"
            />
            <Panel.Header
                icon={
                    <img
                        src="/images/trophy.svg"
                        alt="My Rewards"
                        style={{ filter: 'invert(100%)' }}
                        data-cy="my-rewards-icon"
                        data-testid="my-rewards-icon"
                    />
                }
                title="My Rewards"
            />
            <Panel.Body tailwindLayout="flex-1 flex flex-col">
                <Panel
                    variant="secondary"
                    tailwindLayout="flex-1 flex items-center justify-center xl:mb-2"
                >
                    <Panel.Body tailwindLayout="flex items-center justify-center gap-2 md:flex-col md:gap-0">
                        <div className="text-center text-eighteen text-icongreen font-semibold tracking-wide md:text-twentyfour dark:text-darkmodetext">
                            Referral Earnings:
                        </div>
                        <div
                            className="text-center text-twentyfour text-icongreen tracking-wide font-bold md:text-thirtysix dark:text-darkmodetext"
                            data-cy="my-rewards-amount-data"
                        >
                            {currency + cashConverter(amount)}
                        </div>
                    </Panel.Body>
                </Panel>
            </Panel.Body>
            <Panel.Footer>
                <Button
                    label="View all rewards"
                    variant="outlined"
                    dataCy="my-rewards-background"
                    dataTestId="my-rewards-background"
                    onClick={viewAll}
                />
            </Panel.Footer>
        </Panel>
    );
}

export default RewardsCard;
export type { RewardsCardProps };
