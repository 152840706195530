import classNames from 'classnames';
import {
    createRef,
    CSSProperties,
    PropsWithChildren,
    useLayoutEffect
} from 'react';
import useTailwind, { TailwindProps } from '../tailwind/useTailwind';
import usePanel from './usePanel';

interface PanelBodyProps extends TailwindProps {
    className?: string;
    ['data-cy']?: string;
    ['data-testid']?: string;
    style?: CSSProperties;
}

function PanelBody({
    className,
    ['data-cy']: dataCy,
    ['data-testid']: dataTestId,
    style,
    tailwindLayout,
    tailwindFontColor = 'text-fontprimary dark:text-darkmodetext',
    tailwindFontSize = 'text-twenty md:font-medium',
    children,
    ...restProps
}: PropsWithChildren<PanelBodyProps>) {
    const wrapperRef = createRef<HTMLDivElement>();
    const contentWrapperRef = createRef<HTMLDivElement>();
    const { collapsed } = usePanel();

    const layoutClasses = 'overflow-hidden relative';
    const animationClasses = 'transition-all';

    const cssClass = useTailwind(
        {
            ...restProps,
            tailwindLayout: classNames(layoutClasses, tailwindLayout),
            tailwindFontColor,
            tailwindFontSize
        },
        classNames(animationClasses, className)
    );

    useLayoutEffect(() => {
        if (wrapperRef.current && contentWrapperRef.current) {
            if (collapsed) {
                wrapperRef.current.style.maxHeight = '0';
            } else if (collapsed !== undefined) {
                const { height: contentWrapperHeight } =
                    contentWrapperRef.current.getBoundingClientRect();
                wrapperRef.current.style.maxHeight = `${contentWrapperHeight}px`;
            }
        }
    }, [contentWrapperRef, collapsed]);

    return (
        <div
            className={cssClass}
            data-cy={dataCy}
            data-testid={dataTestId}
            style={style}
            ref={wrapperRef}
        >
            {collapsed !== undefined ? (
                <div ref={contentWrapperRef}>{children}</div>
            ) : (
                children
            )}
        </div>
    );
}

export default PanelBody;
export type { PanelBodyProps };
