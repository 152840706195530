/**
 * React imports
 */
import { createContext, useContext } from 'react';

/**
 * Third party imports
 */
import axios from 'axios';

// Create App Context
export const HttpContext = createContext();

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}`;

/**
 * @function HttpWrapper - Globally available HTTP functions
 */
export const HttpWrapper = ({ children }) => {
    const fetchProviderAuth = () => {
        try {
            return `${process.env.REACT_APP_AUTHORITY}?amr_values=ngcmfa&response_type=${process.env.REACT_APP_AUTHORITY_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
        } catch (error) {
            return error.response;
        }
    };

    const getUserData = async () => {
        try {
            const url = `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_AUTH_USER_PATH}`;
            const { data } = await axios.get(url);
            return data;
        } catch (error) {
            return error.response;
        }
    };

    /**
     * @function updateUser - update a user's division and/or currency
     */
    const updateUser = async (payload) => {
        try {
            const url = `${
                process.env.REACT_APP_API_ENDPOINT +
                process.env.REACT_APP_AUTH_USER_PATH
            }`;
            const { data } = await axios.patch(url, payload);
            return data;
        } catch (error) {
            return error.response;
        }
    };

    /**
     * @function fetchAllReferrals - get all referrals submitted
     */
    const fetchAllReferrals = async () => {
        try {
            const url = `${`${process.env.REACT_APP_API_ENDPOINT}leads/`}`;
            const { data } = await axios.get(url);
            return data;
        } catch (error) {
            return error.response;
        }
    };

    /**
     * @function fetchTotalAwards - get list of awards across multiple award types
     */
    const fetchTotalAwards = async () => {
        try {
            const url = `${`${process.env.REACT_APP_API_ENDPOINT}awards/total/`}`;
            const { data } = await axios.get(url);
            return data;
        } catch (error) {
            return error.response;
        }
    };

    /**
     * @function fetchDashboard - get all dashboard data
     */
    const fetchDashboard = async () => {
        try {
            const url = `${process.env.REACT_APP_API_ENDPOINT}dashboard/`;
            const { data } = await axios.get(url);
            return data;
        } catch (error) {
            return error.response;
        }
    };

    /**
     * @function fetchCompanies - get a list of companies for create-new-lead form
     */
    const fetchCompanies = async () => {
        try {
            const url = `${`${process.env.REACT_APP_API_ENDPOINT}companies/`}`;
            const { data } = await axios.get(url);
            return data;
        } catch (error) {
            return error.response;
        }
    };

    /**
     * @function getDashboard - get all dashboard data
     */
    const getDashboard = () => {
        const url = `dashboard/`;
        const source = axios.CancelToken.source();
        const request = axios.get(`${baseUrl}${url}`, {
            cancelToken: source.token
        });
        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message ||
                        'Failed to fetch dashboard data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    /**
     * @function getMultiRewards - fetches all of a user's multi reward data
     */
    const getMultiRewards = () => {
        const url = `rewards/multi/`;
        const source = axios.CancelToken.source();
        const request = axios.get(`${baseUrl}${url}`, {
            cancelToken: source.token
        });
        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message ||
                        'Failed to fetch multi rewards data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    const fetchComps = () => {
        const url = `companies/`;
        const source = axios.CancelToken.source();
        const request = axios.get(`${baseUrl}${url}`, {
            cancelToken: source.token
        });
        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message ||
                        'Failed to fetch companies data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    const postLead = (data) => {
        const url = `leads/`;
        const source = axios.CancelToken.source();
        const request = axios.post(`${baseUrl}${url}`, data, {
            cancelToken: source.token
        });
        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message || 'Failed to post data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    const fetchReferrals = (
        page,
        status,
        awardedBool,
        filterMapping,
        count
    ) => {
        const url = `leads/`;
        const source = axios.CancelToken.source();

        let query = '?';
        if (page) {
            query += `page=${page}`;
        }
        if (status) {
            if (query !== '?') query += '&';
            query += `status=${status}`;
        }
        if (awardedBool) {
            query += 'awarded_filter=true';
        }
        if (filterMapping) {
            query += 'filter_mapping=true';
        }
        if (count) {
            query += 'count=true';
        }

        const request = axios.get(`${baseUrl}${url}${query}`, {
            cancelToken: source.token
        });
        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message ||
                        'Failed to fetch leads data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    const fetchAReferral = ({ id }) => {
        const url = 'lead/';
        const source = axios.CancelToken.source();
        const request = axios.get(`${baseUrl}${url}${id}`, {
            cancelToken: source.token
        });
        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message ||
                        'Failed to fetch lead data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    /**
     * @function fetchFullRewards - fetches all of a user's reward data
     */
    const fetchFullRewards = () => {
        const url = 'rewards/full/';
        const source = axios.CancelToken.source();
        const request = axios.get(`${baseUrl}${url}`, {
            cancelToken: source.token
        });
        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message ||
                        'Failed to fetch full rewards data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    /**
     * @function fetchFullAwards - fetches all of a user's award data
     */
    const fetchFullAwards = (page, count) => {
        const url = 'awards/full/';
        const source = axios.CancelToken.source();

        let query = '?';
        if (page) {
            query += `page=${page}`;
        }
        if (count) {
            if (query !== '?') query += '&';
            query += `count=${count}`;
        }

        const request = axios.get(`${baseUrl}${url}${query}`, {
            cancelToken: source.token
        });

        request
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                if (![404, 422].includes(error?.response?.status)) {
                    const message =
                        error?.response?.data?.message ||
                        'Failed to fetch full awards data';
                    return message;
                }
            });
        return { request, cancel: source.cancel };
    };

    // Global state
    const sharedState = {
        fetchProviderAuth,
        getUserData,
        updateUser,
        fetchAllReferrals,
        fetchTotalAwards,
        fetchCompanies,
        fetchComps,
        fetchDashboard,
        getDashboard,
        fetchFullRewards,
        fetchFullAwards,
        getMultiRewards,
        postLead,
        fetchReferrals,
        fetchAReferral
    };

    return (
        <HttpContext.Provider value={sharedState}>
            {children}
        </HttpContext.Provider>
    );
};

export const useHttpContext = () => {
    return useContext(HttpContext);
};
