import React from 'react';
import Panel from '../../uiLib/panel';

const SmallContainer = ({ children, title }) => {
    return (
        <Panel variant="primary" className="flex-1">
            <Panel.Header title={title} />
            <Panel variant="secondary" className="flex-1 justify-center">
                <div className="text-center text-twentyfour text-icongreen tracking-wide font-bold md:text-thirtysix dark:text-darkmodetext">
                    {children}
                </div>
            </Panel>
        </Panel>
    );
};

export default SmallContainer;
