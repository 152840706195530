import React, { FC } from 'react';
import '../../index.css';

type ButtonTypes = {
    /**
     * Label of the button.
     */
    label: string;
    /**
     * Cypress testing tag.
     */
    dataCy: string;
    /**
     * RTL testing tag.
     */
    dataTestId: string;
    /**
     * Button click action.
     */
    onClick(): void;
    /**
     * Button events disabled.
     */
    disabled?: boolean;
};

const BASE_BUTTON = `inline-block py-3 px-6 text-modalblue font-semibold text-base
bg-white transition-all duration-75 focus:outline-none hover:shadow-lg hover:bg-primary
hover:text-white active:font-light min-w-full dark:bg-darkappbg`;

const DISABLED_BUTTON = `inline-block py-3 px-6 font-semibold transition-all duration-75
min-w-full text-disabledbuttontext bg-disabledbutton hover:shadow-none cursor-not-allowed`;

/**
 * Primary UI component for user interaction
 */
export const ModalButton: FC<ButtonTypes> = ({
    label,
    dataCy,
    dataTestId,
    onClick,
    disabled
}) => {
    const buttonCssClasses = disabled ? DISABLED_BUTTON : BASE_BUTTON;

    return (
        <div className="flex w-full justify-center items-center">
            <button
                onClick={onClick}
                className={buttonCssClasses}
                data-cy={dataCy}
                data-testid={dataTestId}
                disabled={disabled}
            >
                <span className="dark:text-white">{label}</span>
            </button>
        </div>
    );
};
