/* eslint-disable @typescript-eslint/no-explicit-any */

import Panel from '../../uiLib/panel';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';
import currencySymbol from '../../utilities/currencySym';
import cashConverter from '../../utilities/moneyFormatter';
import ProgressBar from './ProgressBar';
import ProgressCircle from './ProgressCircle';

interface StampCardProps extends TailwindProps {
    data: any | undefined;
    user: any | undefined;
    responsiveness: 'responsive' | 'large' | 'small';
}

function StampCard({
    data,
    user,
    responsiveness = 'responsive',
    ...restProps
}: StampCardProps) {
    const currency = user ? user?.currency?.toLowerCase() : '';
    const progress = user?.stamps * 10 || 0;
    const referralsInOffer = progress / 10;
    const symbol = currencySymbol(user?.currency);
    const reward = cashConverter(data?.[`reward_${currency?.toLowerCase()}`]);

    return (
        <Panel
            tailwindPosition="relative"
            loading={!data || !user}
            {...restProps}
        >
            {(responsiveness === 'large' ||
                responsiveness === 'responsive') && (
                <img
                    src="/images/gift-card.svg"
                    alt="My Stamps"
                    className="hidden xl:block absolute transform scale-x-flip bottom-4 left-4 h-16"
                    data-cy="stampcard-img"
                    data-testid="stampcard-img"
                />
            )}
            <Panel.Body
                tailwindLayout="flex-1 xl:flex flex-col items-center xl:pb-10"
                // className="z-10"
            >
                <div data-cy="stampcard-reward-text">
                    <span className="font-bold">{user?.first_name}</span>,
                    you've had{' '}
                    <span className="font-bold">{referralsInOffer}</span>
                    {referralsInOffer > 1 ? ' referrals' : ' referral'} that
                    {referralsInOffer > 1 ? ' have' : ' has'} reached Offer in
                    Salesforce. Only{' '}
                    <span className="font-bold">{`${
                        10 - referralsInOffer
                    }`}</span>{' '}
                    more to go until you receive a{' '}
                    <span
                        className="font-bold"
                        data-cy="stampcard-reward-amount"
                    >{`${symbol}${reward}`}</span>{' '}
                    voucher.
                </div>
                {responsiveness === 'large' && (
                    <ProgressCircle
                        tailwindLayout="flex-1 flex items-center"
                        progress={progress}
                        strokeWidth={20}
                        reduction={0}
                        hideBall
                        className="mx-auto"
                    />
                )}
                {responsiveness === 'small' && (
                    <ProgressBar
                        tailwindLayout="flex-1 items-stretch w-full"
                        stamp={referralsInOffer}
                    />
                )}
                {responsiveness === 'responsive' && (
                    <>
                        <ProgressCircle
                            tailwindLayout="flex-1 hidden xl:flex items-center"
                            progress={progress}
                            strokeWidth={20}
                            reduction={0}
                            hideBall
                        />
                        <ProgressBar
                            tailwindLayout="flex-1 xl:hidden"
                            stamp={referralsInOffer}
                        />
                    </>
                )}
            </Panel.Body>
        </Panel>
    );
}

export default StampCard;
export type { StampCardProps };
