import DashboardContainer from '../containers/Dashboard/DashboardContainer';
import React from 'react';

const Dashboard = () => {
    return (
        <div data-cy="dashboard-page" data-testid="dashboard-page">
            <DashboardContainer />
        </div>
    );
};

export default Dashboard;
