interface ReferralsDetailsPanelSectionProps {
    label: string;
    value: string;
    testTag?: string;
    dataCy?: string;
    dataTestId?: string;
}

function ReferralsDetailsPanelSection({
    label,
    value,
    dataCy,
    dataTestId
}: ReferralsDetailsPanelSectionProps) {
    return (
        <div
            className="flex flex-row justify-between gap-3 my-3"
            data-cy={dataCy + '-container'}
            data-testid={dataTestId + '-container'}
        >
            <div
                className="text-black text-sixteen md:text-semibold dark:text-darkmodetext"
                data-cy={dataCy + '-label'}
                data-testid={dataTestId + '-label'}
            >
                {label}
            </div>
            <div
                className="text-sixteen md:text-medium font-mont dark:text-darkmodetext self-center md:max-h-32 overflow-hidden overflow-ellipsis md:overflow-y-auto"
                data-cy={dataCy + '-value'}
                data-testid={dataTestId + '-value'}
            >
                {value !== '' ? value : '-'}
            </div>
        </div>
    );
}

export default ReferralsDetailsPanelSection;
export type { ReferralsDetailsPanelSectionProps };
