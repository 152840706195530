import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ShowModalContext } from '../../context/ShowModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Modal() {
    const [open, setOpen] = useState(false);
    const { showModal, setShowModal, modalContent, closeOnBackdrop, showCross } =
        useContext(ShowModalContext);

    const close = useCallback(() => {
        setShowModal(false);
    }, []);

    useEffect(() => {
        setOpen(showModal);
    }, [showModal]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={() => {
                    setShowModal(closeOnBackdrop ? false : showModal)
                }}
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="relative inline-block align-bottom bg-white rounded-lg p-0 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full dark:bg-darkappbg"
                            data-cy="modal-component"
                            data-testid="modal-component"
                        >
                            {modalContent}
                            {closeOnBackdrop || showCross && (
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="absolute top-3 right-3 text-fontprimary cursor-pointer"
                                    onClick={close}
                                />
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
