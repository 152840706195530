import React, { useState, useEffect } from 'react';
import FormField from '../FormField/FormField';
import Input from './Input';
import { useFormContext } from 'react-hook-form';
import './input.css';

export default function FormInput({
    label,
    id,
    tooltip,
    component,
    handleHelp,
    dataCy,
    dataTestId,
    type,
    name,
    placeholder,
    defaultValue,
    maxLength,
    hasCount,
    isRequired
}) {
    const {
        register,
        formState: { errors },
        watch
    } = useFormContext();
    const [count, setCount] = useState(0);
    const field = watch(name, '');

    useEffect(() => {
        setCount(field.length);
    }, [field]);

    return (
        <FormField
            label={label}
            id={id}
            error={errors?.[name]?.message}
            tooltip={tooltip}
            component={component}
            handleHelp={handleHelp}
            dataCy={dataCy}
            hasCount={hasCount}
            count={count}
            maxChars={maxLength}
            isRequired={isRequired}
        >
            <Input
                type={type}
                id={id}
                placeholder={placeholder}
                defaultValue={defaultValue}
                {...register(name)}
                maxLength={maxLength}
                dataCy={dataCy}
                dataTestId={dataTestId}
            />
        </FormField>
    );
}
