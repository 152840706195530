import React, { useContext } from 'react';
import { ShowModalContext } from '../../context/ShowModalContext';
import { ModalButton } from '../../ui/ModalButton/ModalButton';

const PoiModal = () => {
    const { setShowModal } = useContext(ShowModalContext);
    return (
        <div>
            <div className="p-8 pb-8">
                <h4
                    className="text-fontprimary font-bold pb-5 md:text-twenty dark:text-darkmodetext"
                    data-cy="modal-body-header"
                    data-testid="modal-body-header"
                >
                    Product&#40;s&#41; and Solutions
                </h4>
                <p
                    className="font-light text-black md:text-eighteen dark:text-darkmodetext"
                    data-cy="modal-body-text"
                    data-testid="modal-body-text"
                >
                    Please list all products&#47;services&#47;solutions the
                    client has expressed an interest in. Remember, if there are
                    Assurance AND Escrow requirements, raise these as two
                    separate referrals. This will subsequently be qualified by
                    the sales owner against their existing pipeline. You will be
                    notified at each stage the opportunity moves on.
                </p>
            </div>
            <div className="flex-grow border-t border-2 border-solid border-gray-200 bg-gray-200"></div>
            <div className="flex justify-center m-0 p-0">
                <ModalButton
                    label="Close"
                    dataCy="modal-close-button"
                    dataTestId="modal-close-button"
                    onClick={() => setShowModal(false)}
                />
            </div>
        </div>
    );
};

export default PoiModal;
