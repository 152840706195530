import React, { useContext } from 'react';
import { ShowModalContext } from '../../context/ShowModalContext';
import { ModalButton } from '../../ui/ModalButton/ModalButton';

const CountryModal = () => {
    const { setShowModal } = useContext(ShowModalContext);
    return (
        <div>
            <div className="p-8 pb-8">
                <h4
                    className="text-fontprimary font-bold pb-5 md:text-twenty dark:text-darkmodetext"
                    data-cy="modal-body-header"
                    data-testid="modal-body-header"
                >
                    Company Location
                </h4>
                <p
                    className="font-light text-black md:text-eighteen dark:text-darkmodetext"
                    data-cy="modal-body-text"
                    data-testid="modal-body-text"
                >
                    Please select the country that work is being delivered to
                    for this company and not where the Headquarters of the
                    company is based. This information is to help our admins
                    allocate your referral to the right regional account manager
                    in Salesforce.
                </p>
            </div>
            <div className="flex-grow border-t border-2 border-solid border-gray-200 bg-gray-200"></div>
            <div className="flex justify-center m-0 p-0">
                <ModalButton
                    label="Close"
                    dataCy="modal-close-button"
                    dataTestId="modal-close-button"
                    onClick={() => setShowModal(false)}
                />
            </div>
        </div>
    );
};

export default CountryModal;
