import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../ui/Button/Button';
import { ReactComponent as NotFound } from './page-not-found.svg';

const PageNotFound = () => {
    const history = useHistory();

    const backToDashboard = () => {
        return history.push('/');
    };

    return (
        <div className="h-screen overflow-y-hidden flex flex-col justify-center items-center xl:h-x gap-5 md:gap-10 px-4 -mt-10 xl:mt-0">
            <h1
                className="font-sans text-eighteen md:text-twentyfour lg:text-thirty xl:text-thirtysix dark:text-darkmodetext"
                data-cy="not-found-text"
                data-testid="not-found-text"
            >
                Oops! Page not found!
            </h1>
            <NotFound
                className="h-48 md:h-threefifty lg:h-fivehundred"
                data-cy="not-found-image"
                data-testid="not-found-image"
            />
            <Button
                label="Back to Dashboard"
                variant="primary"
                onClick={backToDashboard}
                dataCy="not-found-button"
                dataTestId="not-found-button"
                disabled={false}
            />
        </div>
    );
};

export default PageNotFound;
