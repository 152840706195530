import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import Asterisk from '../../uiLib/asterisk/asterisk';

const mainHeading =
    'text-sans font-bold text-sixteen text-fontprimary md:text-twentyfour dark:text-darkmodetext';
const subHeading =
    'text-sans font-bold text-fourteen text-fontprimary md:text-twenty dark:text-darkmodetext';

export default function SectionHeader({
    header,
    headingType,
    tooltip,
    handleHelp,
    component,
    isRequired
}) {
    return (
        <div className="flex place-content-between md:mt-4">
            <div>
                <p
                    className={
                        headingType === 'main' ? mainHeading : subHeading
                    }
                    data-cy="section-header-text"
                    data-testid="section-header-text"
                >
                    {header}
                    {isRequired ? <Asterisk /> : null}
                </p>
            </div>
            <div className="flex flex-col justify-center">
                {tooltip ? (
                    <Tooltip
                        tooltip={tooltip}
                        handleHelp={handleHelp}
                        component={component}
                    />
                ) : null}
            </div>
        </div>
    );
}
