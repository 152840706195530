/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React imports
 */
import React, { Fragment, useContext } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { useLocation } from 'react-router-dom';
import { IsAuthenticatedContext } from '../../context/IsAuthenticatedContext';
import { UserContext } from '../../context/UserContext';
/**
 * Third party imports
 */
import { Menu, Transition } from '@headlessui/react';
import { CogIcon, LogoutIcon } from '@heroicons/react/solid';

/**
 * Component imports
 */
import NavMenu from './navMenu/NavMenu';
import NavMenuItem from './navMenu/navMenuItem/NavMenuItem';
import Toggle from '../../ui/Toggle/Toggle';

interface Sizes {
    width: number;
    height: number;
}

const handleLogout = () => {
    window.localStorage.clear();
    window.location.replace(
        `${process.env.REACT_APP_LOGOUT_REDIRECT_URI + '/login'}`
    );
};

const Nav = (): React.ReactElement => {
    // Context calls
    const { user } = useContext(UserContext);
    const { isAuthenticated } = useContext(IsAuthenticatedContext);
    const location = useLocation();
    const size: Sizes | any = useWindowSize();
    const { width } = size;

    return (
        <div
            className="flex lg:flex lg:items-center lg:w-auto w-full"
            id="menu"
        >
            <nav>
                <ul className="flex items-center justify-between text-base text-white pt-0">
                    {width > 767 ? <NavMenu user={user} /> : null}
                    {isAuthenticated && location.pathname !== '/divisions' ? (
                        <Menu
                            as="div"
                            className="relative inline-block text-left focus:outline-none active:outline-none"
                        >
                            {({ open }) => (
                                <>
                                    <div>
                                        <Menu.Button
                                            className="py-2 px-0 text-sm transition-all duration-75 focus:outline-none active:outline-none"
                                            data-cy="navbar-menubutton"
                                        >
                                            <div className="pl-3">
                                                <img
                                                    src="/images/menu-icon.svg"
                                                    alt="Login"
                                                    style={{
                                                        filter: 'invert(100%)'
                                                    }}
                                                />
                                            </div>
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            static
                                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  dark:bg-darknavbar"
                                        >
                                            <div className="py-1">
                                                <NavMenuItem
                                                    dataCy="navbar-faq-item"
                                                    text="Change Division"
                                                    link="/divisions"
                                                    icon={
                                                        <CogIcon
                                                            className="inline-flex mr-3 h-5 w-5 text-gray-400 dark:text-gray-300"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                />
                                                <NavMenuItem
                                                    dataCy="navbar-logout-item"
                                                    text="Logout"
                                                    link="/"
                                                    icon={
                                                        <LogoutIcon
                                                            className="inline-flex mr-3 h-5 w-5 text-gray-400 dark:text-gray-300"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                    handleClick={handleLogout}
                                                />
                                                <Toggle data-cy="navbar-darkmode-toggle" />
                                                <p className="text-gray-700 dark:text-gray-100 block p-4 pb-3 text-xs last:border-b-0 text-center border-t tracking-wide">
                                                    Client Version:{' '}
                                                    {
                                                        process.env
                                                            .REACT_APP_VERSION
                                                    }
                                                </p>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            )}
                        </Menu>
                    ) : null}
                </ul>
            </nav>
        </div>
    );
};

export default Nav;
