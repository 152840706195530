import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from 'react';

interface AreaProps
    extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
    > {
    dataCy?: string;
    dataTestId?: string;
}

const Area = forwardRef<HTMLTextAreaElement, AreaProps>(({ dataCy = '', dataTestId = '', ...restProps }, ref) => {
    return (
        <textarea
            ref={ref}
            className="focus:ring-none focus:border-none block w-full border border-inputborder rounded-crsradius text-fontprimary font-sans text-sixteen dark:bg-darkappbg dark:text-darkmodetext dark:border-darkgreyborder"
            aria-invalid="true"
            aria-describedby="user-textarea"
            data-cy={dataCy + 'text-area'}
            data-testid={dataTestId + 'text-area'}
            {...restProps}
        />
    );
});

export default Area;
