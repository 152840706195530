import HELP_CENTER_ICON from './assets/img/icons/help.svg';
import SEARCH_ICON from './assets/img/icons/search.svg';
import REWARD_ICON from './assets/img/icons/reward.svg';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const { REACT_APP_TENANT_ID } = process.env;
export const { REACT_APP_CLIENT_ID } = process.env;
export const REACT_APP_REDIRECT_URI = 'http://localhost:3000/oauth2/auth';
export const { REACT_APP_LOGOUT_REDIRECT_URI } = process.env;
export const { REACT_APP_AUTHORITY } = process.env;
export const { REACT_APP_AUTHORITY_RESPONSE_TYPE } = process.env;
export const { REACT_APP_AUTH_USER_PATH } = process.env;
export const API_ENDPOINT_REFRESH_TOKEN = `${process.env.REACT_APP_API_ENDPOINT}refresh-token/`;
export const REWARDS_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}rewards/`;
export const REWARDS_MULTI_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}rewards/multi/`;
export const AWARDS_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}awards/`;
export const AWARDS_TOTAL_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}awards/total/`;
export const DASHBOARD_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}dashboard/`;
export const USER_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}user/`;
export const { REACT_APP_TOKEN_PATH } = process.env;
export const DATE_TIME_FORMAT = 'dd/MM/yyyy H:mm:ss';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const ROUTE_LOGIN = '/login';
export const ROUTE_0AUTH = '/oauth2/auth';
export const ROUTE_DIVISIONS = '/divisions';
export const ROUTE_REFERRALS = '/referrals';
export const ROUTE_REWARDS = '/rewards';
export const ROUTE_FAQS = '/FAQs';
export const ROUTE_NEW_LEAD = '/create-new-lead';
export const ROUTE_DASHBOARD = '/';
export const ROUTE_ERROR_503 = '/503';
export const ROUTE_ERROR_403 = '/403';
export const ROUTE_HELP = '/help';
export const ROUTE_MAINTENANCE = '/maintenance';

export const HEADING_ICON_MAP = {
    SEARCH: SEARCH_ICON,
    HELP: HELP_CENTER_ICON,
    REWARD: REWARD_ICON
};

export const Z_INDEXES = {
    SIDE_NAV: 2,
    OVERLAY: 1,
    MODAL: 3,
    LOGOUT: -1,
    MODAL_CLOSE: 4,
    FEEDBACK_TAB: 1,
    COOKIE_TAB: 2
};

export const BREAKPOINTS = {
    MEDIUM: '768px',
    LARGE: '1024px',
    XLARGE: '1400px'
};
