import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { bgColorSwitch, percentageSwitch } from '../../utilities/stateSwitch';

const ReferralDetailsCard = ({ data, disabled }) => {
    let cssClass = useMemo(() => {
        let result =
            'mx-auto px-6 pt-3 pb-1 border-solid border-bordergrey border rounded-lg bg-offwhite md:py-6 md:pb-1 dark:bg-darknavbar dark:border-darkgreyborder';
        if (disabled) result += ' opacity-50';

        return result;
    }, [disabled]);

    return (
        <div
            className={cssClass}
            data-cy="referral-container"
            data-testid="referral-container"
        >
            <Link
                to={`/referrals/${data?.id}`}
                data-cy="view-referral-link"
                data-testid="view-referral-link"
            >
                <div className="font-semibold md:flex-col">
                    <div>
                        <h4 className="sr-only">Status</h4>
                        <div>
                            <div className="place-content-between flex">
                                <div>
                                    <div
                                        className="md:flex md:flex-row text-sixteen font-light text-fontprimary md:gap-1 dark:text-darkmodetext"
                                        data-cy="referral-company-container"
                                        data-testid="referral-company-container"
                                    >
                                        <p
                                            className="font-bold text-fontprimary dark:text-darkmodetext"
                                            data-cy="referral-company-title"
                                            data-testid="referral-company-title"
                                        >
                                            Company:{' '}
                                        </p>
                                        <p
                                            className="text-fourteen font-medium text-fontprimary sm:text-sixteen dark:text-darkmodetext"
                                            data-cy="referral-company-name"
                                            data-testid="referral-company-name"
                                        >
                                            {data?.company_name || 'N/A'}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="flex flex-col place-items-end md:flex md:flex-row md:gap-1 text-sixteen font-light text-fontprimary dark:text-darkmodetext"
                                        data-cy="referral-salesperson-container"
                                        data-testid="referral-salesperson-container"
                                    >
                                        <p
                                            className="font-bold text-fontprimary dark:text-darkmodetext"
                                            data-cy="referral-salesperson-title"
                                            data-testid="referral-salesperson-title"
                                        >
                                            Salesperson:{' '}
                                        </p>
                                        <p
                                            className="text-fourteen font-medium text-fontprimary sm:text-sixteen dark:text-darkmodetext"
                                            data-cy="referral-salesperson-name"
                                            data-testid="referral-salesperson-name"
                                        >
                                            {data?.sf_lead_owner ||
                                                'Unassigned'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 sm:mt-3" aria-hidden="true">
                                <div className="bg-gray-200 rounded-full overflow-hidden dark:bg-darkprogressbg">
                                    <div
                                        className={`h-smbar ${bgColorSwitch(
                                            data?.state
                                        )} rounded-full sm:h-lgbar border border-solid border-barborder dark:border-darkprogressbg`}
                                        style={{
                                            width: percentageSwitch(data?.state)
                                        }}
                                        data-cy="referral-progress-bar"
                                        data-testid="referral-progress-bar"
                                    />
                                </div>
                                <div className="flex hover:hidden w-full justify-center -mt-6">
                                    <p
                                        className={
                                            data?.state === 'Disqualified' ||
                                            data?.state === 'Lost'
                                                ? 'font-medium text-fourteen text-white dark:text-white '
                                                : 'font-medium text-fourteen text-fontprimary dark:text-white '
                                        }
                                        data-cy="state-text"
                                        data-testid="state-text"
                                    >
                                        {data?.state || 'N/A'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-1 mb-0">
                    <div
                        className="flex flex-row justify-end items-center gap-1"
                        data-cy="forward-container"
                        data-testid="forward-container"
                    >
                        <p
                            className="font-semibold text-fourteen text-fontprimary dark:text-darkmodetext"
                            data-cy="forward-text"
                            data-testid="forward-text"
                        >
                            Go to Referral
                        </p>
                        <svg
                            className="w-6 h-6 text-fontprimary dark:text-darkmodetext"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            data-cy="forward-arrow-svg"
                            data-testid="forward-arrow-svg"
                        >
                            <path
                                className="text-fontprimary dark:text-darkmodetext"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                                data-cy="forward-arrow-path"
                                data-testid="forward-arrow-path"
                            ></path>
                        </svg>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default ReferralDetailsCard;
