import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../../context/HttpContext';
import { ThemeContext } from '../../context/ThemeContext';
import useRequest from '../../hooks/useRequest';
import { UserContext } from '../../context/UserContext';
import DashboardLayout from '../../components/Dashboard/DashboardLayout';
import currencySymbol from '../../utilities/currencySym';

export default function DashboardContainer() {
    const { user } = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    const { getDashboard, getMultiRewards } = useContext(HttpContext);

    // useRequest hooks utilising endpoints from HttpContext
    const {
        send: sendDash,
        response: dashData,
        loading: loadingDashData
    } = useRequest(getDashboard);
    const { send: sendReward, response: rewardData } =
        useRequest(getMultiRewards);

    /*
    | Use the useRequest hook to send our requests, for the dashboard
    | and multi-reward data.
    */
    useEffect(() => {
        sendDash();
        sendReward();
    }, []);

    /*
    | Conditionally return DashboardLayout or FullscreenSpinner.
    | FullscreenSpinner show whilst axios requests are loading inside
    | useRequest hooks.
    */
    return (
        <DashboardLayout
            currency={currencySymbol(user?.currency)}
            theme={theme}
            dashboardData={dashData}
            multiData={rewardData}
            user={user}
            loadingDashData={loadingDashData}
        />
    );
}
