import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import GradientBackground from '../ui/GradientBackground/GradientBackground';
import Box from '../ui/Box/Box';
import { Button } from '../ui/Button/Button';
import Select from '../ui/Select/Select';
import { userDivisionOptions, currencies } from '../utilities/consts';
import axios from 'axios';
import DivisionHelper from './ModalContents/DivisionHelper';
import { useHttpContext } from '../context/HttpContext';
import { ShowModalContext } from '../context/ShowModalContext';
import { IsAuthenticatedContext } from '../context/IsAuthenticatedContext';
import { AccessTokenContext } from '../context/AccessTokenContext';
import { UserContext } from '../context/UserContext';
import { ReactComponent as SelectIcon } from '../components/DivisionPage/select.svg';
import { ReactComponent as EmailIcon } from '../components/DivisionPage/email-capture.svg';
import { USER_ENDPOINT } from '../constants';

function DivisionSelect() {
    const { getUserData } = useHttpContext();
    const { setShowModal, setModalContent } = useContext(ShowModalContext);
    const { setIsAuthenticated } = useContext(IsAuthenticatedContext);
    const { setAccessToken } = useContext(AccessTokenContext);
    const { user, setUser } = useContext(UserContext);

    const [stage, setStage] = useState(1);
    const [redirect, setRedirect] = useState(false);
    const [formData, setFormData] = useState({
        department: '',
        currency: ''
    });

    const dflt = { key: 'Select...' };
    const [selectedObj, setSelectedObj] = useState(dflt);

    const handleChange = (e, name) => {
        setFormData({
            ...formData,
            [name]: e.value
        });
        setSelectedObj({ key: e.value });
    };

    const submitDivision = () => {
        setStage(2);
        setSelectedObj(dflt);
    };

    const handleBack = () => {
        setStage(1);
        setFormData({ department: '', currency: '' });
        setSelectedObj(dflt);
    };

    const backToDashboard = () => {
        setRedirect(true);
    };

    const handleHelp = (component) => {
        setShowModal(true);
        setModalContent(() => component);
    };

    const fetchUser = () => {
        getUserData().then((response) => {
            setIsAuthenticated(true);
            setAccessToken(localStorage.getItem('access_token'));
            setUser(response);
            setRedirect(true);
            localStorage.setItem('loggedInUser', JSON.stringify(response));
        });
    };

    const formStage =
        stage === 1 ? (
            <div className="relative">
                <div className="absolute right-0">
                    <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        alt="help"
                        className="w-6 h-6 cursor-pointer text-primary dark:text-darkmodetext"
                        data-cy={'info-modal-trigger'}
                        data-testid={'info-modal-trigger'}
                        onClick={() => handleHelp(<DivisionHelper />)}
                    >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                            className="text-primary dark:text-darkmodetext"
                            data-cy={'info-modal-trigger-path'}
                            data-testid={'info-modal-trigger-path'}
                        ></path>
                    </svg>
                </div>
                <div>
                    <Select
                        title={'Which division do you belong to?'}
                        name="department"
                        menuItems={userDivisionOptions}
                        dataCy={'userdivision-division-select'}
                        dataTestId={'userdivision-division-select'}
                        change={(e) => handleChange(e, 'department')}
                        selectedObj={selectedObj}
                    />
                </div>
                <div className="mt-6 flex place-content-between">
                    <Button
                        variant="contained"
                        disabled={formData.department === ''}
                        dataCy="division-select-button"
                        dataTestId="division-select-button"
                        label="Select Division"
                        onClick={() => submitDivision()}
                    />
                    <div className="hidden font-light text-sm w-20 sm:flex sm:flex-col sm:justify-end sm:items-end">
                        <p
                            data-cy="division-form-step-count"
                            data-testid="division-form-step-count"
                        >
                            Step: {stage}/2
                        </p>
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <div>
                    <Select
                        title={'Please select your payroll currency'}
                        name="currency"
                        menuItems={currencies}
                        dataCy={'userdivision-payroll-select'}
                        dataTestId={'userdivision-payroll-select'}
                        change={(e) => handleChange(e, 'currency')}
                        selectedObj={selectedObj}
                    />
                </div>
                <div className="mt-6">
                    <div className="flex place-content-between">
                        <div className="flex gap-6">
                            <Button
                                variant="outlined"
                                disabled={false}
                                dataCy="currency-back-button"
                                dataTestId="currency-back-button"
                                label="Back"
                                onClick={() => handleBack()}
                            />
                            <Button
                                variant="contained"
                                disabled={formData.currency === ''}
                                dataCy="currency-select-button"
                                dataTestId="currency-select-button"
                                label="Submit"
                                onClick={(e) => submitForm(e)}
                            />
                        </div>
                        <div className="hidden font-light text-sm w-20 sm:flex sm:flex-col sm:justify-end sm:items-end">
                            <p
                                data-cy="division-form-step-count"
                                data-testid="division-form-step-count"
                            >
                                Step: {stage}/2
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );

    let content;

    if (user?.department === null || user?.currency === null) {
        content = (
            <div className="flex flex-col justify-center xl:block">
                <p className="text-center text-white text-sixteen font-extralight leading-tight md:text-xl xl:text-2xl xl:text-left xl:justify-start">
                    Welcome to the Referral App. Please answer the following
                    questions to progress:
                </p>
                <div className="mt-8 xl:mt-20">
                    <Box>{formStage}</Box>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="flex flex-col gap-8 xl:gap-10">
                <div className="justify-center">
                    <div className="flex flex-col gap-6 xl:gap-10 text-white text-sixteen font-extralight md:text-twentyfour text-center xl:text-left xl:text-twenty">
                        <p className="font-medium leading-relaxed">
                            Below is the information you submitted when signing
                            up to the Referral App:
                        </p>
                        <p className="flex flex-col font-semibold  leading-close">
                            <span>
                                Division:{' '}
                                <span className="underline pl-2">
                                    {user?.department}
                                </span>
                            </span>
                            <span>
                                Currency:{' '}
                                <span className="underline pl-2">
                                    {user?.currency}
                                </span>
                            </span>
                        </p>
                        <p className="block ">
                            If this is incorrect or requires changing, please
                            email: referral-ops@nccgroup.com
                        </p>
                    </div>
                </div>
                <div className="flex justify-center xl:justify-start">
                    <Button
                        variant="secondary"
                        dataCy="back-to-dashboard-button"
                        dataTestId="back-to-dashboard-button"
                        label="Go to dashboard"
                        onClick={() => backToDashboard()}
                    />
                </div>
            </div>
        );
    }

    const submitForm = (e) => {
        e.preventDefault();
        axios
            .patch(USER_ENDPOINT, formData)
            .then((res) => {
                if (res.status === 200) {
                    fetchUser();
                    setRedirect(true);
                }
            })
            .catch((err) => {
                console.log('Error:', err);
            });
    };

    const classes = 'max-h-96 flex-shrink xl:h-auto';
    const image =
        user?.department === null || user?.currency === null ? (
            <SelectIcon
                alt="select-image"
                className={classes}
                data-cy="division-select-image"
                data-testid="division-select-image"
            />
        ) : (
            <EmailIcon
                alt="contact-image"
                className={classes}
                data-cy="division-contact-image"
                data-testid="division-contact-image"
            />
        );

    return (
        <>
            {redirect ? (
                <Redirect to="/" />
            ) : (
                <GradientBackground>
                    <div className="flex flex-col pt-20 mb-20 px-6 md:place-content-between xl:items-start xl:float-left xl:p-32 xl:pt-16 xl:w-1/2">
                        {content}
                    </div>
                    <div className="flex h-1/3 flex-col justify-end items-center pb-10 lg:h-full lg:justify-center">
                        {image}
                    </div>
                </GradientBackground>
            )}
        </>
    );
}

export default DivisionSelect;
