import React, { Fragment } from 'react';
import Divider from '../../ui/Divider/Divider';
import Panel from '../../uiLib/panel';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';
import ReferralsDetailsPanelSection, {
    ReferralsDetailsPanelSectionProps
} from './ReferralsDetailsPanelSection';

interface ReferralDetailsPanelProps extends TailwindProps {
    icon: string;
    title: string;
    sections: ReferralsDetailsPanelSectionProps[];
    ['data-cy']?: string;
    ['data-testid']?: string;
}

function ReferralDetailsPanel({
    icon,
    title,
    sections,
    ['data-cy']: dataCy,
    ['data-testid']: dataTestId,
    ...restProps
}: ReferralDetailsPanelProps) {
    return (
        <Panel {...restProps}>
            <Panel.Header
                icon={
                    <img
                        src={`/images/${icon}`}
                        alt={title}
                        style={{
                            filter: 'invert(100%)'
                        }}
                        data-cy={`${dataCy}-icon`}
                        data-testid={`${dataTestId}-icon`}
                    />
                }
                title={title}
                data-cy={dataCy}
            />
            <Panel.Body>
                <Panel variant="secondary">
                    <Panel.Body tailwindLayout="flex flex-col gap-4">
                        {sections.map((section, i) => (
                            <Fragment key={i}>
                                <ReferralsDetailsPanelSection
                                    {...section}
                                    data-cy={`${dataCy}-${section.testTag}`}
                                    data-testid={`${dataTestId}-${section.testTag}`}
                                />
                                {i !== sections.length - 1 && <Divider />}
                            </Fragment>
                        ))}
                    </Panel.Body>
                </Panel>
            </Panel.Body>
        </Panel>
    );
}

export default ReferralDetailsPanel;
