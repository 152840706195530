const monthHandler = (monthNumber: string) => {
    let month = '';
    switch (monthNumber) {
        case '01':
            month = 'Jan';
            break;
        case '02':
            month = 'Feb';
            break;
        case '03':
            month = 'Mar';
            break;
        case '04':
            month = 'Apr';
            break;
        case '05':
            month = 'May';
            break;
        case '06':
            month = 'Jun';
            break;
        case '07':
            month = 'Jul';
            break;
        case '08':
            month = 'Aug';
            break;
        case '09':
            month = 'Sep';
            break;
        case '10':
            month = 'Oct';
            break;
        case '11':
            month = 'Nov';
            break;
        case '12':
            month = 'Dec';
            break;
    }
    return month;
};

export const dateHandler = (date = '') => {
    const splitDate = date.split('-');
    const year = splitDate[0];
    const month = monthHandler(splitDate[1]);
    const day = splitDate[2];
    return `${day} ${month} ${year}`;
};
