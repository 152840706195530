import React from 'react';
import { HttpWrapper } from './context/HttpContext';
import Routes from './Routes';
import { IsAuthenticatedProvider } from './context/IsAuthenticatedContext';
import { AccessTokenProvider } from './context/AccessTokenContext';
import { UserProvider } from './context/UserContext';
import { ShowModalProvider } from './context/ShowModalContext';
import { ThemeProvider } from './context/ThemeContext';

function App(): React.ReactElement {
    return (
        <ThemeProvider>
            <IsAuthenticatedProvider>
                <AccessTokenProvider>
                    <UserProvider>
                        <HttpWrapper>
                            <ShowModalProvider>
                                <Routes />
                            </ShowModalProvider>
                        </HttpWrapper>
                    </UserProvider>
                </AccessTokenProvider>
            </IsAuthenticatedProvider>
        </ThemeProvider>
    );
}

export default App;
