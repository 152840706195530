import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
    CSSProperties,
    MouseEventHandler,
    PropsWithChildren,
    ReactElement
} from 'react';
import useTailwind, { TailwindProps } from '../tailwind/useTailwind';
import usePanel from './usePanel';

interface PanelHeaderProps extends TailwindProps {
    className?: string;
    collapsible?: boolean;
    ['data-cy']?: string;
    ['data-testid']?: string;
    identifier?: string;
    icon?: ReactElement;
    onClick?: MouseEventHandler<HTMLDivElement>;
    style?: CSSProperties;
    title?: string;
}

function PanelHeader({
    className,
    collapsible,
    icon,
    'data-cy': dataCy,
    'data-testid': dataTestId,
    onClick,
    identifier,
    style,
    tailwindLayout = 'flex items-center gap-4',
    tailwindSize = 'transition-all mb-ten xl:mb-twenty collapsed:mb-0',
    tailwindFontSize = 'text-sixteen sm:text-eighteen md:text-twenty xl:text-twentyone 2xl:text-twentyfour',
    title,
    children,
    ...restProps
}: PropsWithChildren<PanelHeaderProps>) {
    const { loading } = usePanel();
    const cssClass = useTailwind(
        {
            ...restProps,
            tailwindLayout: classNames(loading ? 'z-10' : null, tailwindLayout),
            tailwindSize,
            tailwindFontSize
        },
        className
    );

    const iconCssClass = useTailwind({
        tailwindLayout: 'flex',
        tailwindBackground: 'bg-icongreen rounded-lg',
        tailwindSize: 'h-smicon w-smicon sm:h-lgicon sm:w-lgicon p-2'
    });

    const titleCssClass = useTailwind({
        tailwindFontColor: 'text-fontprimary dark:text-darkmodetext',
        tailwindFontSize: 'font-bold'
    });

    const contentCssClass = useTailwind({
        tailwindLayout: 'flex items-center gap-2',
        tailwindSize: 'ml-auto'
    });

    return (
        <div
            className={cssClass}
            onClick={onClick}
            style={style}
            data-cy={dataCy}
            data-testid={dataTestId}
        >
            {icon && <div className={iconCssClass}>{icon}</div>}
            {title && (
                <div
                    className={titleCssClass}
                    data-cy={`${dataCy}-title`}
                    data-testid={`${dataCy}-title`}
                >
                    {title}
                </div>
            )}
            {(children || collapsible) && (
                <div className={contentCssClass}>
                    {children && !loading && <div>{children}</div>}
                    {collapsible && (
                        <FontAwesomeIcon
                            className="transition-all transform scale-y-100 collapsed:scale-y-flip dark:text-darkmodetext"
                            icon={faChevronUp}
                            data-cy={`${identifier}-chevron`}
                            data-testid={`${identifier}-chevron`}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default PanelHeader;
export type { PanelHeaderProps };
