import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ROUTE_LOGIN, ROUTE_MAINTENANCE } from './constants';

function getLocalAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    return accessToken;
}

function getLocalRefreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    return refreshToken;
}

// Axios interceptor
axios.interceptors.request.use(
    (config) => {
        config.headers['X-UI-VERSION'] = 2.0;
        config.headers['X-IS-WEBAPP'] = 'true';
        const token = getLocalAccessToken();
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err?.response?.status === 503) {
            window.location = ROUTE_MAINTENANCE;
            return Promise.reject(err);
        }

        if (
            err?.response?.status === 401 &&
            originalConfig.url ===
                `${process.env.REACT_APP_API_ENDPOINT}refresh-token/`
        ) {
            window.location = ROUTE_LOGIN;
            localStorage.clear();
            return Promise.reject(err);
        }

        if (err?.response?.status === 401 && !originalConfig._retry) {
            //Access token has expired
            originalConfig._retry = true;
            localStorage.removeItem('access_token');

            return axios
                .get(`${process.env.REACT_APP_API_ENDPOINT}refresh-token/`, {
                    headers: {
                        Authorization: getLocalRefreshToken()
                    }
                })
                .then((res) => {
                    if (res.status === 200) {
                        // 1) put token to LocalStorage
                        localStorage.setItem(
                            'access_token',
                            res.data.access_token
                        );
                        localStorage.setItem(
                            'refresh_token',
                            res.data.refresh_token
                        );
                        // 2) Change Authorization header
                        axios.defaults.headers.common['Authorization'] =
                            res.data.access_token;
                        // 3) return originalRequest object with Axios
                        return axios(originalConfig);
                    }
                });
        }
        return Promise.reject(err);
    }
);

const render = (Component) => {
    return ReactDOM.render(
        <React.StrictMode>
            <Component />
            <div
                id="modal-root"
                data-cy="root-testing-tag"
                data-testid="root-testing-tag"
            />
        </React.StrictMode>,
        document.getElementById('root')
    );
};

render(App);

if (module.hot) {
    module.hot.accept('./App.tsx', () => {
        const HotApp = require('./App.tsx').default;
        render(HotApp);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
