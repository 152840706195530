import Area from '../../ui/Form/FormArea/Area';
import Input from '../../ui/Form/FormInput/Input';

interface MobileReferralsDetailsSectionProps {
    label: string;
    value: string;
    testTag?: string;
    dataCy?: string;
    dataTestId?: string;
}

function MobileReferralsDetailsSection({
    label,
    value,
    dataCy,
    dataTestId,
    ...props
}: MobileReferralsDetailsSectionProps) {
    return (
        <div
            className="flex flex-col justify-between"
            data-cy={dataCy + '-container'}
            data-testid={dataTestId + '-container'}
        >
            <div
                className="text-sixteen md:text-semibold mt-2 mb-3"
                data-cy={dataCy + `${props.testTag}` + '-label'}
                data-testid={dataTestId + '-label'}
            >
                {label}
            </div>
            {label !== 'Additional information' &&
            label !== 'Product of interest' ? (
                <Input
                    value={value !== '' ? value : '-'}
                    readOnly
                    data-cy={dataCy + '-value'}
                    data-testid={dataTestId + '-value'}
                />
            ) : (
                <Area
                    value={value !== '' ? value : '-'}
                    name={label}
                    id={label}
                    readOnly
                    rows={3}
                    dataCy={dataCy}
                    dataTestId={dataTestId}
                />
            )}
        </div>
    );
}

export default MobileReferralsDetailsSection;
export type { MobileReferralsDetailsSectionProps };
