import React, {
    useState,
    useEffect,
    useContext,
    useMemo,
    useCallback
} from 'react';
import RewardsComponent from '../../components/RewardsPage/RewardsComponent';
import { HttpContext } from '../../context/HttpContext';
import { UserContext } from '../../context/UserContext';
import useRequest from '../../hooks/useRequest';
import FullscreenSpinner from '../../ui/FullscreenSpinner/FullscreenSpinner';

const RewardsContainer = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { user } = useContext(UserContext);
    const { fetchFullAwards, getMultiRewards, fetchReferrals } =
        useContext(HttpContext);

    const count = 3;

    // useRequest hooks
    const {
        send: requestFullAward,
        response: fullAwardData,
        loading: fullAwardLoading
    } = useRequest(fetchFullAwards);
    const {
        send: requestMultiReward,
        response: multiRewardData,
        loading: multiRewardLoading
    } = useRequest(getMultiRewards);
    const {
        send: requestReferral,
        response: referralData,
        loading: referralLoading
    } = useRequest(fetchReferrals);

    useEffect(() => {
        requestFullAward([currentPage, count]);
        requestMultiReward();
        requestReferral([undefined, undefined, true]);
    }, []);

    const isLoading = useMemo(
        () => multiRewardLoading || referralLoading,
        [multiRewardLoading, referralLoading]
    );

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    useEffect(() => {
        requestFullAward([currentPage, count]);
    }, [currentPage, count]);

    return isLoading ? (
        <FullscreenSpinner />
    ) : (
        <RewardsComponent
            fullAwardLoading={fullAwardLoading}
            fullAwardData={fullAwardData}
            multiRewardData={multiRewardData}
            referralData={referralData}
            user={user}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
        />
    );
};

export default RewardsContainer;
