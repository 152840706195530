import React from 'react';
import Panel from '../../uiLib/panel';
import { Button } from '../Button/Button';
import { useHistory } from 'react-router-dom';


const NoReferralsCard = () => {
    const history = useHistory();
    const handleRedirect = () => {
        history.push('/create-new-lead')
    }

    return (
        <Panel variant='secondary' tailwindLayout='flex flex-col gap-5 items-center'>

            <p className='text-fourteen md:text-sixteen text-primarytext dark:text-darkmodetext'>You currently have no rewards. Please submit a referral</p>
            <img
                src="/images/preferences-popup.svg"
                alt="Latest referral"
                className='h-16 md:h-24 xl:h-44 max-h-44 flex-shrink'
                data-cy="latest-referral-image"
                data-testid="latest-referral-image"
            />
            <Button label='Submit new lead' onClick={handleRedirect} />
        </Panel>
    )
}

export default NoReferralsCard;
