import { PropsWithChildren } from 'react';
import BasePanel, { PanelProps } from './Panel';
import BasePanelBody, { PanelBodyProps } from './PanelBody';
import BasePanelFooter, { PanelFooterProps } from './PanelFooter';
import BasePanelHeader, { PanelHeaderProps } from './PanelHeader';

type PanelType = {
    (props: PropsWithChildren<PanelProps>): JSX.Element;
    Header(props: PropsWithChildren<PanelHeaderProps>): JSX.Element;
    Body(props: PropsWithChildren<PanelBodyProps>): JSX.Element;
    Footer(props: PropsWithChildren<PanelFooterProps>): JSX.Element;
};

const Panel: PanelType = BasePanel as PanelType;
Panel.Header = BasePanelHeader;
Panel.Body = BasePanelBody;
Panel.Footer = BasePanelFooter;

export default Panel;
export type { PanelProps, PanelBodyProps, PanelFooterProps, PanelHeaderProps };
