/**
 * React imports
 */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

/**
 * @function useRequest - A simple hook to wrap your Axios request, giving you response, error, loading and cancelled info.
 * @param {function} requestFunc - Function that returns an Axios request
 * @param {boolean} cancelOnUnmount - Wether or not to cancel any
 */
export const useRequest = (requestFunc, cancelOnUnmount = true) => {
    const mounted = useRef(true);
    const request = useRef();

    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [failed, setFailed] = useState(false);

    // This fixes loading spinner issue after a hot-reload
    useEffect(() => {
        mounted.current = true;
    }, []);

    /**
     * Makes a new request using the request function passed into the useRequest hook
     * @param {array} params - An array of params that are passed to the request func
     * @param {boolean} cancelLast - Wether or not to cancel the last request (if still pending)
     */
    const send = (params = [], cancelLast = true) => {
        setError(undefined);
        setLoading(true);
        setFailed(false);
        setCancelled(false);

        // Create and store current request
        const lastRequest = request.current;
        const thisRequest = requestFunc(...params);
        request.current = thisRequest;

        // Cancel last request (if cancelLast == true)
        if (cancelLast && typeof lastRequest?.cancel === 'function') {
            lastRequest.cancel();
        }

        // Set up response handlers - we only want to update the state if this request is still the
        // current request and that the component has not unmounted (user changed route or whatever)
        (thisRequest?.request || thisRequest)
            .then((resp) => {
                if (request.current === thisRequest && mounted.current) {
                    setResponse(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (request.current === thisRequest && mounted.current) {
                    setResponse(undefined);
                    if (axios.isCancel(err)) {
                        setCancelled(true);
                    } else {
                        setError(err);
                    }
                    setFailed(true);
                    setLoading(false);
                }
            });

        return thisRequest;
    };

    /**
     * Cancels any currently pending request
     */
    const cancel = () => {
        if (typeof request.current?.cancel === 'function') {
            request.current?.cancel();
        }
    };

    // Cancel any pending requests upon unmount (unless cancelOnUnmount === false)
    useEffect(() => {
        return () => {
            mounted.current = false;
            if (cancelOnUnmount) {
                cancel();
            }
        };
    }, []);

    return { send, cancel, response, loading, error, failed, cancelled };
};

export default useRequest;
