import React from 'react';
import Panel from '../../uiLib/panel';
import { Reward } from '../../core/api/models/reward';
import { dateHandler } from '../../utilities/dateHandler';
import cashConverter from '../../utilities/moneyFormatter';
import currencySymbol from '../../utilities/currencySym';
interface RewardProps {
    reward: Reward;
    currency: string;
}

const RewardDetailCard = ({ reward, currency }: RewardProps) => {
    const textStyles =
        'font-bold text-fourteen md:text-sixteen xl:text-twenty font-sans m-0 p-0';
    return (
        <Panel variant="secondary">
            <Panel.Body
                tailwindLayout="grid gap-y-4 gap-x-2"
                style={{ gridTemplateColumns: '1fr auto' }}
                tailwindFontColor={
                    reward.paid ? 'text-icongreen' : 'text-nurture'
                }
            >
                <p className={textStyles + ' truncate'}>
                    {reward.awarded_for.company_name}
                </p>
                <p className="font-bold text-sixteen md:text-eighteen xl:text-twenty justify-self-end">
                    {currencySymbol(currency) + cashConverter(reward.amount)}
                </p>

                <p className={textStyles}>{dateHandler(reward.awarded_on)}</p>

                <p className={textStyles + ' justify-self-end'}>
                    {reward.paid ? 'Paid' : 'Pending'}
                </p>
            </Panel.Body>
        </Panel>
    );
};

export default RewardDetailCard;
