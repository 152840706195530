import { ReactNode } from 'react';
import Panel from '../../uiLib/panel';

interface HeadingCardProps {
    label: ReactNode | string;
    value: ReactNode;
    testTag?: string;
    title?: string;
}

const HeadingCard = ({ label, value, testTag, title }: HeadingCardProps) => {
    return (
        <Panel
            className="flex-1 justify-center"
            data-cy={`${testTag}-container`}
            data-testid={`${testTag}-container`}
        >
            <Panel.Body>
                <div className="flex flex-row gap-4 justify-between">
                    <div
                        className="text-eighteen md:text-twenty md:font-semibold"
                        data-cy={`${testTag}-label`}
                        data-testid={`${testTag}-label`}
                    >
                        {label}
                    </div>
                    <div
                        className="text-eighteen truncate md:text-twenty"
                        data-cy={`${testTag}-value`}
                        data-testid={`${testTag}-value`}
                        title={title}
                    >
                        {value}
                    </div>
                </div>
            </Panel.Body>
        </Panel>
    );
};

export default HeadingCard;
