import React, { useContext } from 'react';
import { ShowModalContext } from '../../context/ShowModalContext';
import { ModalButton } from '../../ui/ModalButton/ModalButton';

const CompanyNameModal = () => {
    const { setShowModal } = useContext(ShowModalContext);
    return (
        <div>
            <div className="p-8 pb-8">
                <h4
                    className="text-fontprimary font-bold pb-5 md:text-twenty dark:text-darkmodetext"
                    data-cy="modal-body-header"
                    data-testid="modal-body-header"
                >
                    Company Name
                </h4>
                <p
                    className="font-light text-black md:text-eighteen dark:text-darkmodetext"
                    data-cy="modal-body-text"
                    data-testid="modal-body-text"
                >
                    <span className="block">
                        To search for an existing company, please enter the
                        first 3-4 characters of the company name.
                    </span>{' '}
                    <span className="block">
                        If it is a new company name, please type the new company
                        name and press enter.
                    </span>
                </p>
            </div>
            <div className="flex-grow border-t border-2 border-solid border-gray-200 bg-gray-200"></div>
            <div className="flex justify-center m-0 p-0">
                <ModalButton
                    label="Close"
                    dataCy="modal-close-button"
                    dataTestId="modal-close-button"
                    onClick={() => setShowModal(false)}
                />
            </div>
        </div>
    );
};

export default CompanyNameModal;
