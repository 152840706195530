import React, { useContext } from 'react';
import { ShowModalContext } from '../../context/ShowModalContext';
import { ModalButton } from '../../ui/ModalButton/ModalButton';

const DivisionHelper = () => {
    const { setShowModal } = useContext(ShowModalContext);
    return (
        <div>
            <div className="p-8 pb-8">
                <h4
                    className="font-bold pb-5"
                    data-cy="modal-body-header"
                    data-testid="modal-body-header"
                >
                    Which division do you belong to?
                </h4>
                <p
                    className="font-light"
                    data-cy="modal-body-text"
                    data-testid="modal-body-text"
                >
                    Please select the division and region of NCC Group that you
                    work in/are line managed in. Group Functions are defined as;
                    HR, Product &amp; Development, Global Governance, Group
                    Comms, Finance, Corporate IT, Legal &amp; Procurement
                </p>
            </div>
            <div className="flex-grow border-t border-2 border-solid border-gray-200 bg-gray-200"></div>
            <div className="flex justify-center m-0 p-0">
                <ModalButton
                    label="Close"
                    dataCy="modal-close-button"
                    dataTestId="modal-close-button"
                    onClick={() => setShowModal(false)}
                />
            </div>
        </div>
    );
};

export default DivisionHelper;
