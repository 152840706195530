import React, { useEffect, useState } from 'react';
import FormField from '../FormField/FormField';
import Area from './Area';
import { useFormContext } from 'react-hook-form';
import './textarea.css';

export default function FormArea({
    label,
    id,
    tooltip,
    component,
    handleHelp,
    name,
    placeholder,
    defaultValue,
    rows,
    maxLength,
    hasCount,
    dataCy,
    dataTestId,
    isRequired
}) {
    const {
        register,
        formState: { errors },
        watch
    } = useFormContext();
    const [count, setCount] = useState();
    const field = watch(name, '');

    useEffect(() => {
        setCount(field?.length);
    }, [field]);

    return (
        <FormField
            label={label}
            id={id}
            error={errors?.[name]?.message}
            tooltip={tooltip}
            component={component}
            handleHelp={handleHelp}
            dataCy={dataCy}
            hasCount={hasCount}
            count={count}
            maxChars={maxLength}
            isRequired={isRequired}
        >
            <Area
                rows={rows}
                id={id}
                placeholder={placeholder}
                defaultValue={defaultValue}
                {...register(`${name}`)}
                maxLength={maxLength}
                dataCy={dataCy}
                dataTestId={dataTestId}
            />
        </FormField>
    );
}

/*


<textarea
                rows={rows}
                name={name}
                id={id}
                className={
                    'focus:ring-none focus:border-none block w-full border border-inputborder rounded-md text-fontprimary font-sans text-sixteen dark:bg-darkappbg dark:text-darkmodetext dark:border-darkgreyborder'
                }
                placeholder={placeholder}
                defaultValue={defaultValue}
                aria-invalid="true"
                aria-describedby={aria}
                {...register(`${name}`)}
                maxLength={maxLength}
                data-cy={dataCy + 'text-area'}
                data-testid={dataTestId + 'text-area'}
            />

*/
