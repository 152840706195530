import { percentageSwitch, bgColorSwitch } from '../../utilities/stateSwitch';

interface ReferralDetailProgressBarProps {
    state: string;
}

function ReferralDetailProgressBar({ state }: ReferralDetailProgressBarProps) {
    return (
        <div className="bg-gray-200 rounded-full overflow-hidden dark:bg-darkprogressbg">
            <div
                className={`h-smbar ${bgColorSwitch(
                    state
                )} rounded-full sm:h-lgbar border border-solid border-barborder dark:border-darkprogressbg`}
                style={{
                    width: percentageSwitch(state)
                }}
                data-cy="latest-referral-progress-bar"
                data-testid="latest-referral-progress-bar"
            />
        </div>
    );
}

export default ReferralDetailProgressBar;

export type { ReferralDetailProgressBarProps };
