import {
    Key,
    PropsWithChildren,
    useCallback,
    useEffect,
    useState
} from 'react';
import Panel, {
    PanelProps,
    PanelHeaderProps,
    PanelBodyProps,
    PanelFooterProps
} from '../panel';
import useMediaQuery, { BREAKPOINTS } from '../utils/useMediaQuery';

interface PanelGroupPanel {
    key: Key;
    panelProps?: PanelProps;
    headerProps?: PropsWithChildren<
        Omit<PanelHeaderProps, 'collapsible' | 'onClick'>
    >;
    bodyProps?: PropsWithChildren<PanelBodyProps>;
    footerProps?: PropsWithChildren<PanelFooterProps>;
}

interface PanelGroupProps {
    panels: PanelGroupPanel[];
    mobileLength?: number;
}

function PanelGroup({ panels, mobileLength }: PanelGroupProps) {
    const isMobile = useMediaQuery(BREAKPOINTS.mobile);
    const isTablet = useMediaQuery(BREAKPOINTS.tablet);

    const [openedPanel, setOpenedPanel] = useState<Key>();
    const [displayCount, setDisplayCount] = useState(panels.length);

    const togglePanel = useCallback(
        (key: Key) => {
            if (key !== openedPanel) setOpenedPanel(key);
            else setOpenedPanel(undefined);
        },
        [openedPanel]
    );

    const showMore = useCallback(() => {
        if (mobileLength) setDisplayCount(displayCount + mobileLength);
    }, [displayCount, mobileLength]);

    const showLess = useCallback(() => {
        if (mobileLength) setDisplayCount(mobileLength);
    }, [mobileLength]);

    useEffect(() => {
        setDisplayCount(
            (isMobile || isTablet) && mobileLength
                ? mobileLength
                : panels.length
        );
    }, [isMobile, isTablet, mobileLength, panels.length]);

    return (
        <div className="flex flex-col gap-5">
            {panels
                .slice(0, displayCount)
                .map(
                    ({
                        key,
                        panelProps,
                        headerProps,
                        bodyProps,
                        footerProps
                    }) => (
                        <Panel
                            {...panelProps}
                            key={key}
                            collapsed={key !== openedPanel}
                        >
                            <Panel.Header
                                {...headerProps}
                                collapsible
                                onClick={() => togglePanel(key)}
                            />
                            {headerProps && <Panel.Body {...bodyProps} />}
                            {footerProps && <Panel.Footer {...footerProps} />}
                        </Panel>
                    )
                )}
            {(isMobile || isTablet) &&
                mobileLength &&
                displayCount < panels.length && (
                    <div
                        className="text-center cursor-pointer dark:text-darkmodetext"
                        onClick={showMore}
                    >
                        Show more...
                    </div>
                )}

            {(isMobile || isTablet) &&
                mobileLength &&
                displayCount >= panels.length && (
                    <div
                        className="text-center cursor-pointer dark:text-darkmodetext"
                        onClick={showLess}
                    >
                        Show less...
                    </div>
                )}
        </div>
    );
}

export default PanelGroup;
export type { PanelGroupPanel, PanelGroupProps };
