import { useMemo } from 'react';

/**
 * Create an array of consecutive numbers from start value to end value.
 * @param start
 * @param end
 * @returns [start, start+1, ..., end-1, end]
 */
function range(start: number, end: number) {
    const length = end - start + 1;
    return Array.from({ length }, (_value, index) => index + start);
}

interface PaginationConfig {
    /**
     * Currently selected page.
     */
    currentPage: number;

    /**
     * Number of items to display next to current page.
     */
    siblingCount?: number;

    /**
     * Total number of pages.
     */
    totalPages: number;
}

function usePagination({
    currentPage,
    siblingCount = 2,
    totalPages
}: PaginationConfig) {
    const paginationRange = useMemo(() => {
        const mostLeftSiblingPage = Math.max(currentPage - siblingCount, 1);
        const mostRightSiblingPage = Math.min(
            currentPage + siblingCount,
            totalPages
        );

        // Only show dividers if gap is bigger than 1
        const isLeftDividerVisible = mostLeftSiblingPage > 3;
        const isRightDividerVisible = mostRightSiblingPage < totalPages - 2;

        if (!isLeftDividerVisible && !isRightDividerVisible) {
            // Case 1: No separator
            return range(1, totalPages);
        } else if (!isLeftDividerVisible && isRightDividerVisible) {
            // Case 2: No separator on left side.
            return [...range(1, siblingCount * 2 + 3), -1, totalPages];
        } else if (isLeftDividerVisible && isRightDividerVisible) {
            // Case 3: Separator on both sides
            return [
                1,
                -1,
                ...range(
                    currentPage - siblingCount,
                    currentPage + siblingCount
                ),
                -2,
                totalPages
            ];
        } else {
            // Case 4: No separator on right side.
            return [
                1,
                -2,
                ...range(totalPages - siblingCount * 2 - 2, totalPages)
            ];
        }
    }, [currentPage, siblingCount, totalPages]);

    return paginationRange;
}

export default usePagination;
export type { PaginationConfig };
