/**
 * React imports
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Third party imports
 */
import { Menu } from '@headlessui/react';

/**
 * Utility imports
 */
import classNames from '../../../../utilities/utilities';

interface INavMenuItem {
    text: string;
    link: string;
    dataCy: string;
    icon: JSX.Element;
    handleClick?: () => void;
}
const NavMenuItem = ({
    text,
    link,
    dataCy,
    icon,
    handleClick = () => {
        console.log('clicked');
    }
}: INavMenuItem): React.ReactElement => {
    return (
        <Menu.Item>
            {({ active }) => (
                <Link
                    to={link}
                    className={classNames(
                        active
                            ? 'bg-gray-100 text-gray-900 dark:text-gray-100 dark:bg-gray-800'
                            : 'text-gray-700 dark:text-gray-100',
                        'block px-4 py-2 text-sm border-b last:border-b-0'
                    )}
                    onClick={handleClick}
                    data-cy={dataCy}
                >
                    {icon && icon}
                    {text}
                </Link>
            )}
        </Menu.Item>
    );
};

export default NavMenuItem;
