/**
 * React imports
 */
import React from 'react';

/**
 * Third party imports
 */
import { Menu } from '@headlessui/react';

interface IUser {
    first_name: string;
    last_name: string;
    department: string;
}

/**
 * Component imports
 */
import Spinner from '../../../ui/spinner/Spinner';

const NavMenu = ({ user }: { user: IUser }): React.ReactElement => {
    return (
        <Menu as="div" className="relative inline-block text-left ml-3">
            {() => (
                <>
                    <div>
                        <Menu.Button
                            className="inline-flex cursor-default py-2 px-3 border border-white rounded-md text-sm transition-all duration-75 focus:outline-none"
                        >
                            {!user ? (
                                <Spinner color="blue" size={5} dataCy="navmenu-spinner" dataTestId="navmenu-spinner" />
                            ) : (
                                <>
                                    <span>
                                        {user?.first_name} {user?.last_name}
                                        {user?.department &&
                                            ` | ${user.department}`}
                                    </span>
                                </>
                            )}
                        </Menu.Button>
                    </div>
                </>
            )}
        </Menu>
    );
};

export default NavMenu;
