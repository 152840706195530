/**
 * React imports
 */
import React from 'react';
import LoginComponent from '../components/login/LoginComponent';

/**
 * Component imports
 */
import { useHttpContext } from '../context/HttpContext';

const Login = () => {
    const { fetchProviderAuth } = useHttpContext();

    const idpLoginHandler = async () => {
        const signInViaMsAD = await fetchProviderAuth();
        window.location.href = signInViaMsAD;
    };

    return <LoginComponent loginHandler={idpLoginHandler}></LoginComponent>;
};

export default Login;
