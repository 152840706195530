const Tag = ({ bgColor, children }) => {
    return (
        <div className={`px-4 rounded-lg w-32 md:w-52 ${bgColor}`}>
            <div className="text-center text-eighteen md:text-twenty font-semibold text-white">
                {children}
            </div>
        </div>
    );
};

export default Tag;
