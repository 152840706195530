import React from 'react';
import usePagination from '../../hooks/usePagination';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';

const Pagination = ({
    className = '',
    onPageChange,
    siblingCount = 1,
    currentPage,
    totalPages,
    totalCount,
    pageSize = 5
}) => {
    const paginationRange = usePagination({
        currentPage,
        siblingCount,
        pageSize: pageSize,
        totalPages
    });

    const pageStart = (currentPage - 1) * pageSize + 1;
    const pageEnd = Math.min(pageStart + pageSize - 1, totalCount);

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const activePageNumber =
        'text-fontprimary font-sans font-semibold text-lg dark:text-white ';
    const inactivePageNumber =
        'text-gray-400 font-sans font-semibold text-lg dark:text-darkmodetext dark:text-opacity-75';

    const disablePrevious =
        currentPage !== 1
            ? 'text-fontprimary cursor-pointer '
            : 'text-gray-300 cursor-not-allowed dark:text-opacity-50 ';

    const disableNext =
        currentPage !== totalPages
            ? 'text-fontprimary cursor-pointer '
            : 'text-gray-300 cursor-not-allowed dark:text-opacity-50 ';

    return (
        <div className={className}>
            <ul className="flex place-content-between">
                <li
                    onClick={currentPage !== 1 ? onPrevious : undefined}
                    data-cy="pagination-previous"
                    className={
                        disablePrevious +
                        'flex gap-1 justify-center items-center font-sans font-semibold text-lg dark:text-darkmodetext'
                    }
                >
                    <LeftArrow
                        arrowDisabled={currentPage === 1 ? true : false}
                    />
                    <span className="hidden xl:block">Previous</span>
                </li>
                {paginationRange.map((pageNumber) => {
                    if (pageNumber < 0) {
                        return (
                            <li
                                className="font-sans font-semibold text-fontprimary text-lg dark:text-darkmodetext"
                                key={pageNumber}
                            >
                                &#8230;
                            </li>
                        );
                    }

                    const selectedColour =
                        pageNumber === currentPage
                            ? activePageNumber
                            : inactivePageNumber;

                    return (
                        <li
                            className="w-10 flex justify-center cursor-pointer"
                            key={pageNumber}
                            data-cy="pagination-page-number"
                            onClick={() => onPageChange(pageNumber)}
                        >
                            <p className={selectedColour}>{pageNumber}</p>
                        </li>
                    );
                })}
                <li
                    onClick={currentPage !== totalPages ? onNext : undefined}
                    data-cy="pagination-next"
                    className={
                        disableNext +
                        'flex gap-1 justify-center items-center cursor-pointer font-sans font-semibold  text-lg dark:text-darkmodetext'
                    }
                >
                    <span className="hidden xl:block">Next</span>
                    <RightArrow
                        arrowDisabled={
                            currentPage === totalPages ? true : false
                        }
                    />
                </li>
            </ul>
            <div className="flex justify-center mt-4">
                <p
                    className="font-sans font-semibold text-fontprimary text-sm dark:text-darkmodetext"
                    data-cy="pagination-summary"
                    data-testid="pagination-summary"
                >
                    {totalCount
                        ? `${pageStart}-${pageEnd} out of ${totalCount}`
                        : null}
                </p>
            </div>
        </div>
    );
};

export default Pagination;
