// Styles used by Select component
export const custom = (theme, hideIcons) => {
    let result = {};
    if (theme === 'light') {
        result = {
            option: (provided, state) => ({
                padding: '5px 10px',
                font: 'Inter',
                fontWeight: 400,
                color: state.isSelected ? '#FFFFFF' : '#888888',
                background: state.isSelected ? '#252D7C' : '#FFFFFF',
                '&:hover': {
                    background: '#252D7C',
                    color: '#FFFFFF'
                }
            }),
            input: (provided) => ({
                ...provided,
                '&:focus': {
                    outline: '#252D7C !important;'
                }
            }),
            control: () => ({
                font: 'Inter',
                fontWeight: 400,
                background: '#FFFFFF',
                display: 'flex',
                border: '1px solid #888888',
                borderRadius: '8px',
                color: '#888888',
                fontSize: '16px'
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return { ...provided, opacity, transition };
            },
            menuList: (provided) => ({
                ...provided,
                backgroundColor: '#FFFFFF',
                borderRadius: '8px'
            })
        };
    }
    if (theme === 'dark') {
        result = {
            option: (provided, state) => ({
                padding: '5px 10px',
                font: 'Inter',
                fontWeight: 400,
                color: state.isSelected ? '#000000' : '#888888',
                background: state.isSelected ? '#CCCCCC' : '#333333',
                '&:hover': {
                    background: '#CCCCCC',
                    color: '#000000'
                }
            }),
            input: (provided) => ({
                ...provided,
                color: '#CCCCCC',
                '&:focus': {
                    outline: '#252D7C !important;'
                }
            }),
            control: () => ({
                background: '#333333',
                display: 'flex',
                border: '1px solid #666666',
                borderRadius: '8px',
                color: '#FFFFFF'
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return { ...provided, opacity, transition, color: '#CCCCCC' };
            },
            menu: (provided) => ({
                ...provided,
                backgroundColor: '#333333',
                border: '1px solid #666666'
            }),
            menuList: (provided) => ({
                ...provided,
                backgroundColor: '#333333',
                borderRadius: '8px'
            }),
            dropdownIndicator: () => ({
                margin: '0px 8px',
                color: '#666666',
                '&:hover': {
                    color: '#CCCCCC'
                }
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: '#666666'
            }),
            clearIndicator: (provided) => ({
                ...provided,
                color: '#666666',
                '&:hover': {
                    color: '#CCCCCC'
                }
            })
        };
    }
    if (hideIcons) {
        result.dropdownIndicator = () => ({ display: 'none' });
        result.indicatorSeparator = () => ({ display: 'none' });
    }
    return result;
};

/*
========================
  STYLABLE CLASS NAMES
========================

clearIndicator
container
control
dropdownIndicator
group
groupHeading
indicatorsContainer
indicatorSeparator
input
loadingIndicator
loadingMessage
menu
menuList
menuPortal
multiValue
multiValueLabel
multiValueRemove
noOptionsMessage
option
placeholder
singleValue
valueContainer

*/
