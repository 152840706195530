/**
 * React imports
 */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTE_LOGIN } from '../../constants';
import { IsAuthenticatedContext } from '../../context/IsAuthenticatedContext';
import { UserContext } from '../../context/UserContext';

const PrivateRoute = ({ Component, ...rest }) => {
    const { isAuthenticated } = useContext(IsAuthenticatedContext);
    const { user } = useContext(UserContext);

    if (
        (user.currency === null || user.department === null) &&
        isAuthenticated
    ) {
        return (
            <>
                <Redirect to="/divisions" />
                <Route {...rest} render={(props) => <Component {...props} />} />
            </>
        );
    }

    if (user.currency !== null && user.department !== null && isAuthenticated) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    if (!isAuthenticated) {
        return <Redirect to={ROUTE_LOGIN} />;
    }
};

export default PrivateRoute;
