export const percentageSwitch = (state) => {
    let perc;
    switch (state) {
        case 'New':
            perc = 10;
            break;
        case 'Nurture':
            perc = 20;
            break;
        case 'In Progress':
            perc = 30;
            break;
        case 'Qualified':
            perc = 50;
            break;
        case 'Proposed':
            perc = 70;
            break;
        case 'Disqualified':
            perc = 100;
            break;
        case 'Lost':
            perc = 100;
            break;
        case 'Won':
            perc = 100;
            break;
        case 'Invoiced':
            perc = 100;
            break;
        default:
            break;
    }
    return `${perc}%`;
};

export const bgColorSwitch = (state) => {
    let clr;
    switch (state) {
        case 'New':
            clr = 'bg-new';
            break;
        case 'Nurture':
            clr = 'bg-nurture';
            break;
        case 'In Progress':
            clr = 'bg-progress';
            break;
        case 'Qualified':
            clr = 'bg-qualified';
            break;
        case 'Proposed':
            clr = 'bg-proposed';
            break;
        case 'Disqualified':
            clr = 'bg-disqualified';
            break;
        case 'Lost':
            clr = 'bg-lost';
            break;
        case 'Won':
            clr = 'bg-won';
            break;
        case 'Invoiced':
            clr = 'bg-invoiced';
            break;
        default:
            break;
    }
    return clr;
};
