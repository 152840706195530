import classNames from 'classnames';
import { CSSProperties, PropsWithChildren, useMemo } from 'react';
import GradientLoader from '../../ui/GradientLoader/GradientLoader';

import useTailwind, { TailwindProps } from '../tailwind/useTailwind';
import PanelContextProvider, { PanelContextValue } from './PanelContext';

interface PanelProps extends TailwindProps {
    className?: string;
    /**
     * Controls the collapse state of the panel.
     * If defined panel will be treated as collapsible.
     */
    collapsed?: boolean;
    ['data-cy']?: string;
    ['data-testid']?: string;
    style?: CSSProperties;
    loading?: boolean;
    /**
     * Overrides tailwind background with predefined classes.
     */
    variant?: 'primary' | 'secondary';
}

function Panel({
    className,
    ['data-cy']: dataCy,
    ['data-testid']: dataTestId,
    collapsed,
    tailwindBackground,
    tailwindBorder = 'border-bordergrey border rounded-xl',
    tailwindLayout = 'flex flex-col',
    tailwindSize = 'p-4 xl:p-5',
    style,
    variant = 'primary',
    children,
    loading,
    ...restProps
}: PropsWithChildren<PanelProps>) {
    let backgroundClasses = tailwindBackground;
    switch (variant) {
        case 'primary':
            backgroundClasses =
                'bg-offwhite dark:bg-darknavbar dark:border-darkgreyborder';
            break;
        case 'secondary':
            backgroundClasses =
                'bg-white dark:bg-darkappbg dark:border-darkgreyborder';
    }

    const cssClass = useTailwind(
        {
            ...restProps,
            tailwindBackground: backgroundClasses,
            tailwindBorder,
            tailwindLayout: classNames(
                loading ? 'relative' : null,
                tailwindLayout
            ),
            tailwindSize
        },
        classNames(className ?? '', { collapsed })
    );

    const contextValue = useMemo<PanelContextValue>(
        () => ({
            collapsed,
            loading
        }),
        [collapsed, loading]
    );

    return (
        <PanelContextProvider value={contextValue}>
            <div
                className={cssClass}
                data-cy={dataCy}
                data-testid={dataTestId}
                style={style}
            >
                {children}
                {loading && <GradientLoader />}
            </div>
        </PanelContextProvider>
    );
}

export default Panel;
export type { PanelProps };
