const RightArrow = ({ arrowDisabled }) => {
    return (
        <svg
            className={
                !arrowDisabled
                    ? 'w-6 h-6 text-fontprimary dark:text-darkmodetext'
                    : 'w-6 h-6 dark:text-darkmodetext dark:text-opacity-50'
            }
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            data-cy="pagination-right-arrow-svg"
            data-testid="pagination-right-arrow-svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
                data-cy="pagination-right-arrow-path"
                data-testid="pagination-right-arrow-path"
            ></path>
        </svg>
    );
};

export default RightArrow;
