import { Link } from 'react-router-dom';

export default function ArrowIcon({ destination }) {
    return (
        <Link
            to={destination}
            data-cy="back-arrow-link"
            data-testid="back-arrow-link"
        >
            <svg
                className="w-6 h-6 text-primary dark:text-darkmodetext"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                data-cy="back-arrow-svg"
                data-testid="back-arrow-svg"
            >
                <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
                    clipRule="evenodd"
                    data-cy="back-arrow-path"
                    data-testid="back-arrow-path"
                ></path>
            </svg>
        </Link>
    );
}
