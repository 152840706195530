import HeadingCard from './HeadingCard';
import Tag from '../../ui/Tag/Tag.jsx';
import { bgColorSwitch } from '../../utilities/stateSwitch';

interface StatusCardProps {
    state: string | undefined;
}

function StatusCard({ state }: StatusCardProps) {
    return (
        <HeadingCard
            label="Status:"
            value={<Tag bgColor={bgColorSwitch(state)}>{state}</Tag>}
            testTag="status-card"
        />
    );
}

export default StatusCard;
