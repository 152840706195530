import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navbar from './containers/Navbar/Navbar';
import Dashboard from './pages/Dashboard';
import DivisionSelect from './pages/DivisionSelect';
import NewLead from './pages/NewLead';
import Referrals from './pages/Referrals';
import ReferralView from './pages/ReferralView';
import RewardsContainer from './containers/RewardsPage/RewardsContainer';
import Authenticate from './containers/Authenticate/Authenticate';
import Login from './pages/Login';
import {
    ROUTE_DASHBOARD,
    ROUTE_LOGIN,
    ROUTE_NEW_LEAD,
    ROUTE_REFERRALS,
    ROUTE_0AUTH,
    ROUTE_DIVISIONS,
    ROUTE_FAQS,
    ROUTE_REWARDS,
    ROUTE_MAINTENANCE
} from './constants';
import PrivateRoute from './containers/PrivateRoute/PrivateRoute';
import PageNotFound from './containers/PageNotFound/PageNotFound';
import Modal from './ui/Modal/Modal';
import { useHttpContext } from './context/HttpContext';
import { IsAuthenticatedContext } from './context/IsAuthenticatedContext';
import { UserContext } from './context/UserContext';
import { AccessTokenContext } from './context/AccessTokenContext';
import FaqsPage from './pages/FaqsPage';
import MaintenanceMode from './containers/MaintenanceMode/MaintenanceMode';
import ScrollToTop from './ui/ScrollToTop';

function Routes() {
    const { getUserData } = useHttpContext();
    const { isAuthenticated, setIsAuthenticated } = useContext(
        IsAuthenticatedContext
    );
    const { setAccessToken } = useContext(AccessTokenContext);
    const { setUser } = useContext(UserContext);

    useEffect(() => {
        if (
            isAuthenticated &&
            window.location.pathname !== ROUTE_LOGIN &&
            window.location.pathname !== ROUTE_MAINTENANCE
        ) {
            getUserData().then((user) => {
                setIsAuthenticated(
                    localStorage.getItem('access_token') ? true : false
                );
                setUser(user);
                setAccessToken(localStorage.getItem('access_token'));
                localStorage.setItem('loggedInUser', JSON.stringify(user));
            });
        }
    }, []);

    if (window.location.pathname === '/login') {
        localStorage.clear();
    }

    return (
        <Router>
            <ScrollToTop />
            {isAuthenticated &&
                window.location.pathname !== ROUTE_LOGIN &&
                window.location.pathname !== ROUTE_MAINTENANCE && <Navbar />}
            <Switch>
                <Route path={ROUTE_LOGIN} component={Login} />
                <Route path={ROUTE_0AUTH} component={Authenticate} />
                <PrivateRoute
                    exact
                    path={ROUTE_DASHBOARD}
                    component={Dashboard}
                />
                <PrivateRoute
                    exact
                    path={ROUTE_DIVISIONS}
                    component={DivisionSelect}
                />
                <PrivateRoute exact path={ROUTE_NEW_LEAD} component={NewLead} />
                <PrivateRoute
                    exact
                    path={`${ROUTE_REFERRALS}/:id`}
                    component={ReferralView}
                />
                <PrivateRoute
                    exact
                    path={ROUTE_REFERRALS}
                    component={Referrals}
                />
                <PrivateRoute
                    exact
                    path={ROUTE_REWARDS}
                    component={RewardsContainer}
                />
                <PrivateRoute exact path={ROUTE_FAQS} component={FaqsPage} />
                <Route
                    exact
                    path={ROUTE_MAINTENANCE}
                    component={MaintenanceMode}
                />
                <Route path="*" component={PageNotFound} />
            </Switch>
            <Modal></Modal>
        </Router>
    );
}

export default Routes;
