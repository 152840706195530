export default function Box({ children }) {
    return (
        <div
            className="max-w-7xl mx-auto px-4 py-4  border-solid border-bordergrey border-1 rounded-lg bg-offwhite sm:px-6 lg:px-8 lg:p-8 lg:pb-10"
            //   style={{ border: '1px solid red' }}
        >
            {children}
        </div>
    );
}
