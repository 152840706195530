import { useState } from 'react';

const useShowModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [closeOnBackdrop, setCloseOnBackdrop] = useState(true);
    const [showCross, setShowCross] = useState(false);

    return {
        showModal,
        setShowModal,
        modalContent,
        setModalContent,
        closeOnBackdrop,
        setCloseOnBackdrop,
        showCross,
        setShowCross
    };
};

export default useShowModal;
