import React, { useContext } from 'react';
import { ShowModalContext } from '../../context/ShowModalContext';
import { ModalButton } from '../../ui/ModalButton/ModalButton';

const OriginModal = () => {
    const { setShowModal } = useContext(ShowModalContext);
    return (
        <div>
            <div className="p-8 pb-8">
                <h4
                    className="text-fontprimary font-bold pb-5 md:text-twenty dark:text-darkmodetext"
                    data-cy="modal-body-header"
                    data-testid="modal-body-header"
                >
                    How did this referral originate?
                </h4>
                <p
                    className="font-light text-black md:text-eighteen dark:text-darkmodetext"
                    data-cy="modal-body-text"
                    data-testid="modal-body-text"
                >
                    Please select an option that most closely matches how this
                    referral originated, to ensure your referral gets qualified
                    correctly. These options align with our Valid Referrals
                    criteria, but if an accurate origin is not listed, please
                    select 'Other' and add the context in the Lead Information
                    field.
                </p>
            </div>
            <div className="flex-grow border-t border-2 border-solid border-gray-200 bg-gray-200"></div>
            <div className="flex justify-center m-0 p-0">
                <ModalButton
                    label="Close"
                    dataCy="modal-close-button"
                    dataTestId="modal-close-button"
                    onClick={() => setShowModal(false)}
                />
            </div>
        </div>
    );
};

export default OriginModal;
