import React from 'react';
import BackArrow from '../BackArrow/BackArrow';

const PageWrapper = ({ children, destination, header }) => {
    return (
        <>
            <div className="mx-auto flex flex-col items-center px-4 md:px-thirty mb-5 xl:mt-2 xl:px-forty xl:items-start xl:flex-row xl:gap-thirty">
                <div className="m-0 p-0 w-full xl:h-full">
                    <BackArrow destination={destination} header={header} />
                    {children}
                </div>
            </div>
        </>
    );
};

export default PageWrapper;
