import { useMemo } from 'react';

interface TailwindProps {
    /**
     * Tailwind background related classes: background.
     */
    tailwindBackground?: string;
    /**
     * Tailwind border related classes: border and border radius.
     */
    tailwindBorder?: string;
    /**
     * Tailwind layout related classes: display (flex and grid)
     */
    tailwindLayout?: string;
    /**
     * Tailwind position related classes: position
     */
    tailwindPosition?: string;
    /**
     * Tailwind size related classes: padding, margin, width and height
     */
    tailwindSize?: string;
    /**
     * Tailwind font related classes: font family
     */
    tailwindFontFamily?: string;
    /**
     * Tailwind font related classes: font size, font weight
     */
    tailwindFontSize?: string;
    /**
     * Tailwind font related classes: font color
     */
    tailwindFontColor?: string;
}

function useTailwind(props: TailwindProps, className?: string) {
    const tailwindProps = (({
        tailwindBackground,
        tailwindBorder,
        tailwindLayout,
        tailwindPosition,
        tailwindSize,
        tailwindFontFamily,
        tailwindFontSize,
        tailwindFontColor
    }) => ({
        tailwindBackground,
        tailwindBorder,
        tailwindLayout,
        tailwindPosition,
        tailwindSize,
        tailwindFontFamily,
        tailwindFontSize,
        tailwindFontColor
    }))(props);

    const deps = Object.values(tailwindProps).concat(className);

    const cssClass = useMemo(() => {
        let result = Object.values(props).filter(Boolean).join(' ');
        if (className) result += ` ${className}`;
        return result;
    }, deps);

    return cssClass;
}

export default useTailwind;
export type { TailwindProps };
