import React from 'react';
import '../../index.css';

const primary = `text-white bg-primary hover:shadow-lg hover:bg-primary 
hover:text-white active:font-light`;

const secondary = `text-primary border-solid border-primary bg-white 
hover:shadow-lg hover:bg-primary hover:text-white active:font-light`;

const outlined = `text-primary border-solid border-2 border-primary 
bg-offwhite hover:shadow-lg hover:bg-primary hover:text-white
active:font-light`;

const disable = `text-disabledbuttontext bg-disabledbutton hover:shadow-none 
cursor-not-allowed`;

/**
 * Primary UI component for user interaction
 */
export default function SubmitButton({
    label = 'Submit',
    variant,
    disabled = false,
    dataCy,
    dataTestId
}) {
    let BASE_BUTTON = primary;

    switch (variant) {
        case 'secondary':
            BASE_BUTTON = secondary;
            break;
        case 'outlined':
            BASE_BUTTON = outlined;
            break;
        default:
            BASE_BUTTON = primary;
            break;
    }

    if (disabled) {
        BASE_BUTTON = disable;
    }

    return (
        <div className="w-full flex justify-center items-center">
            <button
                className={
                    'text-white bg-primary hover:shadow-lg hover:bg-primary hover:text-white active:font-light inline-block px-6 font-semibold text-base rounded-md transition-all duration-75 focus:outline-none w-full h-smbtnh sm:h-lgbtnh md:py-3 dark:bg-modalblue dark:border-modalblue ' +
                    BASE_BUTTON
                }
                type="submit"
                disabled={disabled}
                data-cy={dataCy}
                data-testid={dataTestId}
            >
                <span className="dark:text-white">{label}</span>
            </button>
        </div>
    );
}
