import React, { Fragment } from 'react';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';
import MobileReferralsDetailsSection, {
    MobileReferralsDetailsSectionProps
} from './MobileReferralDetailsSection';

interface MobileReferralDetailsPanelProps extends TailwindProps {
    title?: string;
    sections: MobileReferralsDetailsSectionProps[];
    dataCy?: string;
    dataTestId?: string;
}

function MobileReferralDetailsPanel({
    sections,
    dataCy,
    dataTestId,
    ...restProps
}: MobileReferralDetailsPanelProps) {
    return (
        <>
            {sections.map((section, i) => (
                <Fragment key={i}>
                    <MobileReferralsDetailsSection
                        {...section}
                        dataCy={`${dataCy}-${section.testTag}`}
                        dataTestId={`${dataTestId}-${section.testTag}`}
                        {...restProps}
                    />
                </Fragment>
            ))}
        </>
    );
}

export default MobileReferralDetailsPanel;
