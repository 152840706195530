import React from 'react';
import Divider from '../../ui/Divider/Divider';
import EarningsCard from '../../ui/EarningsCard/EarningsCard';
import PageWrapper from '../../ui/PageWrapper/PageWrapper';
import RewardsPanel from '../../ui/RewardsPanel/RewardsPanel';
import SmallContainer from '../../ui/SmallContainer/SmallContainer';
import StampCard from '../../ui/StampCard/StampCard';

const RewardsComponent = ({
    fullAwardData,
    multiRewardData,
    referralData,
    user,
    handlePageChange,
    currentPage,
    fullAwardLoading
}) => {
    const gridGap = 'gap-3 md:gap-4 xl:gap-8';

    return (
        <PageWrapper destination="/" header="My Rewards">
            <div
                className={`mt-4 flex flex-col items-stretch w-full h-full md:flex-row ${gridGap}`}
            >
                <div
                    className={`flex-1 xl:flex-2 flex flex-col xl:place-content-between ${gridGap}`}
                >
                    <StampCard
                        tailwindLayout="md:hidden"
                        data={multiRewardData}
                        user={user}
                        responsiveness="small"
                    />
                    <EarningsCard
                        user={user}
                        awarded={referralData?.awarded?.amount_awarded}
                    />
                    <div
                        className={`flex-1 flex flex-col xl:flex-row ${gridGap}`}
                    >
                        <SmallContainer title="Total Referrals">
                            {referralData?.count || 0}
                        </SmallContainer>
                        <SmallContainer title="Referrals Won">
                            {referralData?.awarded?.won_referrals || 0}
                        </SmallContainer>
                        <SmallContainer title="Vouchers Earned">
                            {referralData?.awarded?.total_vouchers || 0}
                        </SmallContainer>
                    </div>
                </div>

                <Divider className="hidden xl:block" />

                <div className={`flex-1 flex flex-col xl:mt-0 ${gridGap}`}>
                    <StampCard
                        tailwindLayout="hidden md:block xl:hidden"
                        data={multiRewardData}
                        user={user}
                        responsiveness="small"
                    />
                    <RewardsPanel
                        fullAwardLoading={fullAwardLoading}
                        user={user}
                        fullAwardData={fullAwardData}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                        totalPages={fullAwardData?.total_pages}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

export default RewardsComponent;
