import React, { useContext } from 'react';
import Select from 'react-select';
import { ThemeContext } from '../../../context/ThemeContext';
import { custom } from './selectTheme';

const StateSelect = ({
    handleSelect,
    defaultOption,
    options,
    dataCy,
    dataTestId
}) => {
    const { theme } = useContext(ThemeContext);
    return (
        <div className="shadow-lg" data-cy={dataCy} data-testid={dataTestId}>
            <Select
                options={options}
                styles={custom(theme)}
                defaultValue={defaultOption}
                onChange={(e) => handleSelect(e)}
            />
        </div>
    );
};

export default StateSelect;
