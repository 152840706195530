import React, { FC } from 'react';
import '../../index.css';

interface ButtonProps {
    /**
     * Label of the button
     */
    label?: string;
    /**
     * Button click action
     */
    onClick?: () => void;
    /**
     * Controls Button styling options are primary/secondary/outlined
     */
    variant?: string;
    /**
     * Controls if button is disabled
     */
    disabled?: boolean;
    /**
     * Cypress testing tag
     */
    dataCy?: string;
    /**
     * RTL testing tag
     */
    dataTestId?: string;
}

const primary = `text-white bg-primary hover:shadow-lg hover:bg-primary
hover:text-white active:font-light`;

const secondary = `text-primary border-solid border-primary bg-white
hover:shadow-lg hover:bg-primary hover:text-white active:font-light`;

const outlined = `text-primary border-solid border-2 border-primary
bg-offwhite hover:shadow-lg hover:bg-primary hover:text-white
active:font-light`;

const disable = `text-disabledbuttontext bg-disabledbutton hover:shadow-none
cursor-not-allowed`;

/**
 * Primary UI component for user interaction
 */
export const Button: FC<ButtonProps> = ({
    onClick,
    label = 'Submit',
    variant,
    disabled = false,
    dataCy,
    dataTestId
}) => {
    let BASE_BUTTON = primary;

    switch (variant) {
        case 'secondary':
            BASE_BUTTON = secondary;
            break;
        case 'outlined':
            BASE_BUTTON = outlined;
            break;
        default:
            BASE_BUTTON = primary;
            break;
    }

    if (disabled) {
        BASE_BUTTON = disable;
    }

    return (
        <button
            onClick={onClick}
            className={
                'text-twelve xl:text-fourteen 2xl:text-sixteen inline-block px-2 sm:px-4 xl:px-6 font-semibold text-base rounded-md transition-all duration-75 focus:outline-none w-auto h-smbtnh sm:h-lgbtnh md:py-3 dark:bg-modalblue dark:border-modalblue ' +
                BASE_BUTTON
            }
            disabled={disabled}
            data-cy={dataCy}
            data-testid={dataTestId}
        >
            <span className="dark:text-white">{label}</span>
        </button>
    );
};
