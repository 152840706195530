import { useContext, useMemo } from 'react';
import styles from './progress.module.css';
import { ThemeContext } from '../../context/ThemeContext';
import useTailwind from '../../uiLib/tailwind/useTailwind';

function ProgressCircle({
    progress = 0,
    strokeWidth = 4,
    ballStrokeWidth = 16,
    reduction = 0.25,
    background = '#DFEDD8',
    hideBall = false,
    hideValue = false,
    gradient = [
        { stop: 0.0, color: '#38AF00' },
        { stop: 1, color: '#38AF00' }
    ],
    subtitle = '',
    style = {},
    className = '',
    suffix = '%',
    tailwindLayout = ''
}) {
    const cssClass = useTailwind(
        {
            tailwindLayout
        },
        `${className} ${styles.progress}`
    );

    progress = Math.round(progress * 100) / 100;
    const width = 200;
    const center = width / 2;
    const height = 200 || center + center * Math.cos(reduction * Math.PI);
    const unique = useMemo(() => Math.random().toString(), []);
    const rotate = -90;
    const r = center - strokeWidth / 2 - ballStrokeWidth / 2;
    const circumference = Math.PI * r * 2;
    const offset = (circumference * (100 - progress * (1 - reduction))) / 100;

    const { theme } = useContext(ThemeContext);

    return (
        <div
            className={cssClass}
            style={style}
            data-cy="circular-progress-container"
            data-testid="circular-progress-container"
        >
            <svg
                viewBox={`0 0 ${width} ${height}`}
                className={styles.svg}
                data-cy="circular-progress-svg"
                data-testid="circular-progress-svg"
            >
                <defs>
                    <linearGradient
                        id={`gradient${unique}`}
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                    >
                        {gradient.map(({ stop, color }) => (
                            <stop
                                key={stop}
                                offset={stop * 100 + (suffix || '')}
                                stopColor={color}
                            />
                        ))}
                    </linearGradient>
                </defs>
                {!hideValue && (
                    <text
                        x={center}
                        y={110}
                        textAnchor="middle"
                        fontSize="36"
                        fill={theme === 'light' ? '#38AF00' : '#CCCCCC'}
                        className="text-icongreen dark:text-darkmodetext"
                        data-cy="circular-progress-number"
                        data-testid="circular-progress-number"
                    >
                        {progress / 10 + '/10'}
                    </text>
                )}
                <text
                    x={center}
                    y={center + (30 * 3) / 4}
                    textAnchor="middle"
                    fill="#9c9c9c"
                >
                    {subtitle}
                </text>
                <circle
                    transform={`rotate(${rotate} ${center} ${center})`}
                    id="path"
                    cx={center}
                    cy={center}
                    r={r}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={circumference * reduction || 0}
                    fill="none"
                    stroke={theme === 'light' ? background : '#3F3F3F'}
                    strokeLinecap="round"
                    data-cy="circular-progress-circle-one"
                    data-testid="circular-progress-circle-one"
                />
                <circle
                    transform={`rotate(${rotate} ${center} ${center})`}
                    id="path"
                    cx={center}
                    cy={center}
                    r={r}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${circumference}`}
                    strokeDashoffset={offset}
                    fill="none"
                    stroke={`url(#gradient${unique})`}
                    strokeLinecap="round"
                    data-cy="circular-progress-circle-two"
                    data-testid="circular-progress-circle-two"
                />
                {!hideBall && (
                    <circle
                        transform={`rotate(${rotate} ${center} ${center})`}
                        id="path"
                        cx={center}
                        cy={center}
                        r={r}
                        strokeWidth={ballStrokeWidth}
                        strokeDasharray={`1 ${circumference}`}
                        strokeDashoffset={offset}
                        fill="none"
                        stroke={`url(#gradient${unique})`}
                        strokeLinecap="round"
                    />
                )}
            </svg>
        </div>
    );
}

export default ProgressCircle;

// export const Progress = React.memo(_ProgressCircle);
// Progress.displayName = 'Progress';

// export default Progress;
