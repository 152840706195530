import React from 'react';
import { Referral } from '../../core/api/models/referral';
import PageWrapper from '../../ui/PageWrapper/PageWrapper';
import StatusCard from './StatusCard';
import CompanyCard from './CompanyCard';
import ReferralDetailsPanel from './ReferralsDetailsPanel';
import MobileReferralInformationCards from './MobileReferralInformationCards';
import cashAward from '../../utilities/cashAward';

interface ReferralProps {
    referral: Referral | undefined;
    currSymbol: string;
}

const ReferralDetailsComponent = ({ referral, currSymbol }: ReferralProps) => {
    const hiddenInMobile = 'hidden md:flex flex-col';
    const visibleInMobile = 'flex flex-col md:hidden';

    const cashAwardData = cashAward(
        currSymbol,
        referral?.state,
        referral?.full_award
    );

    const salesforcePanelArray = [
        {
            label: 'Salesforce ID',
            value: `${referral?.sf_lead_id}`,
            testTag: 'salesforce-id'
        },
        {
            label: 'Assigned salesperson',
            value: `${referral?.sf_lead_owner}`
        },
        {
            label: 'Closed reason',
            value: `${referral?.closed_reason}`
        }
    ];

    if (referral?.other_closed_reason) {
        salesforcePanelArray.push({
            label: 'Other closed reason',
            value: `${referral?.other_closed_reason}`
        });
    }
    return (
        <PageWrapper destination="/referrals" header="Referral Details">
            <div className="gap-5 mt-4 grid grid-cols-1 md:gap-3 md:grid-cols-2">
                <CompanyCard company={referral?.company_name} />
                <StatusCard state={referral?.state} />
                <ReferralDetailsPanel
                    tailwindLayout={hiddenInMobile}
                    icon="cloud.svg"
                    title="Salesforce Information"
                    data-cy="salesforce-card"
                    data-testid="salesforce-card"
                    sections={salesforcePanelArray}
                />
                <ReferralDetailsPanel
                    tailwindLayout={hiddenInMobile}
                    icon="trophy.svg"
                    title="Reward Information"
                    data-cy="reward-card"
                    data-testid="reward-card"
                    sections={[
                        {
                            label: 'Stamp',
                            value: `${
                                referral?.stamp_awarded
                                    ? 'Rewarded'
                                    : 'Not rewarded'
                            }`
                        },
                        {
                            label: 'Invoiced amount',
                            value: `${currSymbol}${referral?.invoice_amount}`
                        },
                        {
                            label: 'Cash award',
                            value: `${cashAwardData}`
                        }
                    ]}
                />
                <ReferralDetailsPanel
                    tailwindLayout={hiddenInMobile}
                    icon="user.svg"
                    title="Customer Details"
                    data-cy="customer-card"
                    data-testid="customer-card"
                    sections={[
                        {
                            label: 'Full name',
                            value: `${referral?.contact_title} ${referral?.contact_first_name} ${referral?.contact_last_name}`
                        },
                        {
                            label: 'Email address',
                            value: `${referral?.contact_email}`
                        },
                        {
                            label: 'Phone number',
                            value: `${referral?.contact_number}`
                        }
                    ]}
                />
                <ReferralDetailsPanel
                    tailwindLayout={hiddenInMobile}
                    icon="magnifier.svg"
                    title="Referral Information"
                    data-cy="referral-card"
                    data-testid="referral-card"
                    sections={[
                        {
                            label: 'Submitted to',
                            value: `${referral?.division}`
                        },
                        {
                            label: 'Product of interest',
                            value: `${referral?.product_of_interest}`
                        },
                        {
                            label: 'Additional information',
                            value: `${referral?.additional_information}`
                        }
                    ]}
                />

                <MobileReferralInformationCards
                    referral={referral}
                    currSymbol={currSymbol}
                    tailwindLayout={visibleInMobile}
                />
            </div>
        </PageWrapper>
    );
};

export default ReferralDetailsComponent;
