const LeftArrow = ({ arrowDisabled }) => {
    return (
        <svg
            className={
                !arrowDisabled
                    ? 'w-6 h-6 text-fontprimary dark:text-darkmodetext'
                    : 'w-6 h-6 dark:text-darkmodetext dark:text-opacity-50'
            }
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            data-cy="pagination-left-arrow-svg"
            data-testid="pagination-left-arrow-svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
                data-cy="pagination-left-arrow-path"
                data-testid="pagination-left-arrow-path"
            ></path>
        </svg>
    );
};

export default LeftArrow;
