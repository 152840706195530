import { useEffect, useState } from 'react';

const BREAKPOINTS = {
    mobile: '(max-width: 765px)',
    tablet: '(min-width: 766px) and (max-width: 1279px)',
    desktop: '(min-width: 1280px)'
};

function useMediaQuery(query): boolean {
    const [result, setResult] = useState(false);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setResult(matchMedia(query).matches);
        });
        resizeObserver.observe(document.body);

        return () => resizeObserver.disconnect();
    }, [query]);

    return result;
}

export default useMediaQuery;
export { BREAKPOINTS };
