interface ReferralsCompanySectionProps {
    labelClasses: string;
    valueClasses: string;
    company: string;
    state: string;
}

const ReferralsCompanySection = ({
    labelClasses,
    valueClasses,
    company,
    state
}: ReferralsCompanySectionProps) => {
    return (
        <div className="flex justify-between">
            <div className="flex flex-col">
                <div className={labelClasses}>Company:</div>
                <div className={valueClasses} data-cy="latest-referral-company">
                    {company}
                </div>
            </div>
            <div className="flex flex-col items-end">
                <div className={labelClasses}>State:</div>
                <div
                    className={valueClasses}
                    data-cy="latest-referral-state-text"
                >
                    {state}
                </div>
            </div>
        </div>
    );
};

export default ReferralsCompanySection;
