import React, { createContext } from 'react';
import useUser from '../hooks/useUser';

const UserContext = createContext();

function UserProvider({ children }) {
    const { user, setUser } = useUser({});

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserProvider };
