export default function Tooltip({ tooltip, handleHelp, component }) {
    return (
        <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            alt="help"
            className="w-5 h-5 md:w-6 md:h-6 cursor-pointer text-primary dark:text-darkmodetext"
            data-cy={tooltip + 'info-modal-trigger'}
            data-testid={tooltip + 'info-modal-trigger'}
            name={tooltip}
            onClick={() => handleHelp(component)}
        >
            <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
                className="text-primary dark:text-darkmodetext"
                name={tooltip}
                data-cy={tooltip + 'info-modal-trigger-path'}
                data-testid={tooltip + 'info-modal-trigger-path'}
            ></path>
        </svg>
    );
}
