import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { useFormContext, Controller } from 'react-hook-form';
import FormField from '../FormField/FormField';

import AsyncCreatableSelect from 'react-select/async-creatable';
import { custom } from './selectTheme';
import GradientLoader from '../../GradientLoader/GradientLoader';

export default function AsyncSelect({
    label,
    id,
    tooltip,
    component,
    handleHelp,
    name,
    loadOptions,
    maxLength,
    isClearable,
    isMulti,
    hideIcons,
    isDisabled,
    hasCount,
    dataCy,
    dataTestId,
    isRequired,
    loading
}) {
    const { theme } = useContext(ThemeContext);
    const {
        control,
        formState: { errors },
        watch
    } = useFormContext();
    const [count, setCount] = useState(0);
    const field = watch(name, {});

    useEffect(() => {
        setCount(field?.value?.length);
    }, [field]);

    const err = errors?.[name]?.value?.message || errors?.[name]?.message;

    return (
        <FormField
            label={label}
            id={id}
            error={err}
            tooltip={tooltip}
            component={component}
            handleHelp={handleHelp}
            dataCy={dataCy}
            isSelect={true}
            hasCount={hasCount}
            count={count}
            maxChars={maxLength}
            isRequired={isRequired}
        >
            {loading ? (
                <div className="h-thirtysix z-10">
                    <GradientLoader />
                </div>
            ) : (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <AsyncCreatableSelect
                            cacheOptions
                            defaultOptions
                            name={name}
                            {...field}
                            id={id}
                            placeholder="Start typing..."
                            styles={custom(theme, hideIcons)}
                            isClearable={isClearable}
                            isMulti={isMulti}
                            // onChange={(e) => setCount(e.value.length)}
                            maxLength={maxLength}
                            isDisabled={isDisabled}
                            loadOptions={loadOptions}
                            dataCy={dataCy}
                            dataTestId={dataTestId}
                        />
                    )}
                />
            )}
        </FormField>
    );
}
