import GradientLoader from '../../../ui/GradientLoader/GradientLoader';

export default function FormContainer({ wrapperTag, children, loading }) {
    return (
        <div
            className="bg-offwhite container mt-fifteen lg:mt-twenty py-4 px-4 w-full sm:px-6 lg:px-8 border border-solid border-bordergrey rounded-xl max-w-7xl xl:mt-thirty xl:max-w-6xl dark:bg-darknavbar dark:border-darkgreyborder relative"
            data-cy={wrapperTag + '-form-container'}
            data-testid={wrapperTag + '-form-container'}
        >
            {children}
            {loading && <GradientLoader />}
        </div>
    );
}
