import React from 'react';
import Panel from '../../uiLib/panel';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';
import { isUndefined } from 'lodash';

interface TotalCardProps extends TailwindProps {
    number: number | undefined;
}

function TotalCard({ number, ...restProps }: TotalCardProps) {
    /*
    We use the z-index (line 40) to allow some of the Panel content
    to be displayed above the GradientLoader which is displayed,
    whilst a Panel's component is awaiting it's response data from
    the backend.
    */
    return (
        <Panel
            data-cy="totalcard-background"
            data-testid="totalcard-background"
            loading={isUndefined(number)}
            {...restProps}
        >
            <Panel.Header
                icon={
                    <img
                        src="/images/hashtag.svg"
                        alt="Total referrals"
                        className="max-h-20"
                        style={{ filter: 'invert(100%)' }}
                        data-cy="total-referrals-icon"
                        data-testid="total-referrals-icon"
                    />
                }
                title="Total Referrals"
                tailwindSize=""
                data-cy="total-referrals-header"
                data-testid="total-referrals-header"
            >
                <Panel tailwindSize="px-6 z-0" variant="secondary">
                    <Panel.Body
                        tailwindFontColor="text-icongreen dark:text-darkmodetext"
                        tailwindFontSize="text-twentyfour md:text-thirty font-bold"
                        data-cy="total-referrals-number"
                        data-testid="total-referrals-number"
                    >
                        {number ?? 0}
                    </Panel.Body>
                </Panel>
            </Panel.Header>
        </Panel>
    );
}

export default TotalCard;
export type { TotalCardProps };
