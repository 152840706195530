import {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react';
import { useHistory } from 'react-router-dom';
import { ShowModalContext } from '../../context/ShowModalContext';
import { ModalButton } from '../../ui/ModalButton/ModalButton';
import Spinner from '../../ui/spinner/Spinner';

type NewLeadStatus = 'submitted' | 'submitting' | 'error';

interface NewLeadStatusModalProps {
    status: NewLeadStatus;
}

function NewLeadStatusModal({ status }: NewLeadStatusModalProps): ReactElement {
    const { setShowModal, setCloseOnBackdrop } = useContext(ShowModalContext);
    const history = useHistory();

    const navigateToDashboard = useCallback(() => {
        history.push('/');
        setShowModal(false);
        setCloseOnBackdrop(true);
    }, []);

    const navigateToAllReferrals = useCallback(() => {
        history.push('/referrals');
        setShowModal(false);
        setCloseOnBackdrop(true);
    }, []);

    const message = useMemo(() => {
        switch (status) {
            case 'error':
                return 'There has been a problem creating the referral';
            case 'submitted':
                return 'Your referral has been submitted!';
            case 'submitting':
                return 'Your referral is being submitted...';
        }
    }, [status]);

    const buttonsDisabled = status === 'submitting';

    useEffect(() => {
        if (status !== 'submitting') setCloseOnBackdrop(false);
    }, [status]);

    return (
        <div>
            <div className="p-8 pb-8 flex flex-col items-center">
                <h4
                    className="text-fontprimary font-bold pb-5 md:text-twenty dark:text-darkmodetext"
                    data-cy="modal-body-header"
                    data-testid="modal-body-header"
                >
                    {message}
                </h4>
                {status === 'submitting' ? (
                    <Spinner size={10} />
                ) : (
                    <img
                        src="/images/preferences-popup.svg"
                        alt="Latest referral"
                        className="max-h-16 2xl:h-xxlmagni"
                        data-cy="latest-referral-image"
                        data-testid="latest-referral-image"
                    />
                )}
            </div>
            <div className="flex-grow border-t border-2 border-solid border-gray-200 bg-gray-200"></div>
            <div className="flex justify-center m-0 p-0">
                <ModalButton
                    label="Home"
                    dataCy="modal-home-button"
                    dataTestId="modal-home-button"
                    onClick={navigateToDashboard}
                    disabled={buttonsDisabled}
                />
                <div className="flex-grow border-t border-2 border-solid border-gray-200 bg-gray-200"></div>
                <ModalButton
                    label="All Referrals"
                    dataCy="modal-all-referrals-button"
                    dataTestId="modal-all-referrals-button"
                    onClick={navigateToAllReferrals}
                    disabled={buttonsDisabled}
                />
            </div>
        </div>
    );
}

export default NewLeadStatusModal;
export type { NewLeadStatus, NewLeadStatusModalProps };
