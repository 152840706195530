import React, { useState, useEffect, useContext } from 'react';
import NewLeadComponent from '../../components/NewLeadPage/NewLeadComponent';
import {
    newLeadOrigins,
    salutations,
    gpaAnswers,
    divisions
} from '../../utilities/consts';
import { useHttpContext } from '../../context/HttpContext';
import { ShowModalContext } from '../../context/ShowModalContext';
import useRequest from '../../hooks/useRequest';
import NewLeadStatusModal from '../../pages/ModalContents/NewLeadStatusModal';

export default function NewLeadContainer() {
    const { fetchComps, postLead } = useHttpContext();
    const { setShowModal, setModalContent, setCloseOnBackdrop, setShowCross } =
        useContext(ShowModalContext);
    const [companies, setCompanies] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Formats the response data from the fetchCompanies request
    const companyFormatter = (comps) =>
        comps?.map((comp) => ({
            label: comp,
            value: comp,
            name: 'company_name'
        }));

    // Filters companies in the company variable to allow async search
    const filterCompanies = (inputValue) => {
        if (!companies) return;
        let c = [...companies];
        let searchResults = [];

        if (inputValue.length >= 3) {
            searchResults = c.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return searchResults;
        }
    };

    // Handles the company search
    const loadOptions = (inputValue, callback) =>
        setTimeout(() => {
            callback(filterCompanies(inputValue));
        }, 1000);

    const {
        send: getCompanies,
        response: companiesRes,
        loading: companiesLoading
    } = useRequest(fetchComps);

    // Get companies on initial render
    useEffect(() => {
        getCompanies();
    }, []);

    // Once companyRes's data changes, formats the res data and sets to state
    useEffect(() => {
        let formattedComps = companyFormatter(companiesRes);
        setCompanies(formattedComps);
    }, [companiesRes]);

    const {
        send: sendLead,
        response: postResponse,
        failed: postFailed
    } = useRequest(postLead);

    // Handles submission of form
    const onSubmit = (data) => {
        const requestData = {
            ...data,
            company_name: data.company_name.value,
            contact_title: data.contact_title.value,
            division: data.division.value,
            country: data?.country?.value,
            origin: data.origin.value,
            gpa: data.gpa.value,
            added_via: 'Web App'
        };
        if (
            ![
                'Assurance UK and Europe (Iberia & Nordics)',
                'Assurance Benelux (Fox-IT)'
            ].includes(requestData.division)
        ) {
            delete requestData.country;
        }
        sendLead([requestData]);
        setIsSubmitting(true);
    };

    useEffect(() => {
        if (isSubmitting) {
            const status = postResponse
                ? 'submitted'
                : postFailed
                ? 'error'
                : 'submitting';
            setShowModal(true);
            setCloseOnBackdrop(false);
            if (!status === 'submitted' || !status === 'submitting') {
                setShowCross(true);
            }

            setModalContent(() => <NewLeadStatusModal status={status} />);
        }
    }, [postResponse, isSubmitting, postFailed]);

    // Handles tooltips
    const handleHelp = (component) => {
        setShowModal(true);
        setModalContent(() => component);
    };

    return (
        <NewLeadComponent
            origins={newLeadOrigins}
            salutations={salutations}
            gpaAnswers={gpaAnswers}
            divisions={divisions}
            loadOptions={loadOptions}
            onSubmit={onSubmit}
            handleHelp={handleHelp}
            companiesLoading={companiesLoading}
        />
    );
}
