import { useMemo } from 'react';
import { Button } from '../ui/Button/Button';
import PageWrapper from '../ui/PageWrapper/PageWrapper';
import Panel, {
    PanelBodyProps,
    PanelHeaderProps,
    PanelProps
} from '../uiLib/panel';
import PanelGroup, { PanelGroupPanel } from '../uiLib/panelGroup/PanelGroup';
import { ReactComponent as Collaboration } from '../assets/img/faqpage/collaboration.svg';
import { ReactComponent as DesignTeam } from '../assets/img/faqpage/remote-design-team.svg';
import { ReactComponent as WorkTime } from '../assets/img/faqpage/work-time.svg';

interface BackgroundImageProps {
    image: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

function BackgroundImage({ image: Image }: BackgroundImageProps) {
    return (
        <Image
            style={{
                position: 'absolute',
                top: '0',
                left: '60%',
                height: 'calc(100% - 1rem)',
                width: 'calc(40% - 2rem)',
                margin: '0.5rem 1rem'
            }}
        />
    );
}

function FaqsPage() {
    const commonPanelProps: Partial<PanelProps> = { variant: 'secondary' };
    const commonHeaderProps: Partial<PanelHeaderProps> = {
        tailwindFontSize: 'text-sixteen md:text-eighteen'
    };
    const commonBodyProps: Partial<PanelBodyProps> = {
        tailwindFontSize: 'text-sixteen md:text-eighteen'
    };

    const listStyles = 'ml-5 flex flex-col gap-3 list-disc';
    const paragraphStyles = ' xl:text-sixteen';
    const linkStyles = 'text-modalblue underline dark:text-darkmodetext';

    const frequentlyAskQuestions = useMemo<PanelGroupPanel[]>(
        () => [
            {
                key: 'what-is',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'what-is-header',
                    title: 'What is a Referral?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'what-is-body',
                    children: (
                        <p className={paragraphStyles}>
                            A new sales opportunity you identify, where our
                            products/services could help fulfil a client
                            requirement, based on client indication. It can be
                            for an existing customer of the Group, or a brand
                            new one. The important thing is you identifying
                            something the business did not already know about,
                            and that the customer explicitly expressed an
                            interest to you - whether that is from your
                            personal/professional network, or as a follow up to
                            a previous engagement. Just remember, extensions to
                            existing projects, SOW's or licenses are not classed
                            as referrals, and you cannot refer engagement
                            findings/outcomes without evidence the client wants
                            to procure the product/service. Please see below
                            questions for more guidance.
                        </p>
                    )
                }
            },
            {
                key: 'process',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'process-header',
                    title: 'What is the referrals process?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'process-body',
                    children: (
                        <ul className={listStyles + paragraphStyles}>
                            <li>
                                Submit a referral in mobile or web app with as
                                much information as possible.
                            </li>
                            <li>
                                Referral goes direct into Salesforce
                                regional/divisional queues, and gets immediately
                                assigned to a Salesperson.
                            </li>
                            <li>
                                Referral gets qualified against existing sales
                                pipeline and standard qualification criteria.
                            </li>
                            <li>
                                If qualified, your referral will move on through
                                the normal sales lifecycle.
                            </li>
                            <li>
                                If it reaches 'Offer', so it gets proposed to
                                the client, you are eligible for Reward #1 -
                                1/10 stamps towards a voucher!
                            </li>
                            <li>
                                If it reaches 'Invoiced' after being Won, you
                                are eligible for Reward #2 - Cash reward paid in
                                payroll!
                            </li>
                        </ul>
                    )
                }
            },
            {
                key: 'validation',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'validation-header',
                    title: 'What makes a referral valid/non-valid?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'validation-body',
                    children: (
                        <p className={paragraphStyles}>
                            Our validation criteria sets out the difference
                            between a valid and a non-valid referral. You can
                            view the referral criteria on our Referral App
                            SharePoint Site{' '}
                            <a
                                target="_blank"
                                referrerPolicy="no-referrer"
                                href="https://nccgrp.sharepoint.com/sites/ReferralApp/SitePages/Referral-Criteria.aspx"
                                className={linkStyles}
                            >
                                here
                            </a>
                            . If you're unsure where your referral sits within
                            the criteria or have any general questions about
                            this, please contact referrals@nccgroup.com
                        </p>
                    )
                }
            },
            {
                key: 'advices',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'advices-header',
                    title: 'How can I maximise my chances of reward?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'advices-body',
                    children: (
                        <ul className={listStyles + paragraphStyles}>
                            <li>
                                Raise ALL the client requirements at the time in
                                your one referral - only submit multiple
                                referrals if the requirement spans Assurance AND
                                Escrow services.
                            </li>
                            <li>
                                Raise your referral BEFORE contacting a
                                salesperson directly - this will create the
                                Salesforce lead for you and speed up the
                                process. If you need the referral expediting
                                then contact referrals@nccgroup.com.
                            </li>
                            <li>
                                Add context, as much as possible. Are there
                                timelines? Known budgets? How did you find it?
                                What relationship do you have with the client?
                            </li>
                        </ul>
                    )
                }
            },
            {
                key: 'earn-stamps',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'earn-stamps-header',
                    title: 'How do I earn stamps?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'earn-stamps-body',
                    children: (
                        <p className={paragraphStyles}>
                            Earning stamps is simple! When a referral reaches
                            Offer in Salesforce you will automatically earn a
                            stamp. Even if your referral goes on to be lost with
                            the customer, your stamp remains and adds up to the
                            goal of receiving a gift voucher! Please note that
                            stamps are not earned for simply submitting a
                            referral.
                        </p>
                    )
                }
            },
            {
                key: 'help',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'help-header',
                    title: "I've got a referral but I'm not sure if it's valid, who can I speak to?"
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'help-body',
                    children: (
                        <p className={paragraphStyles}>
                            Email referrals@nccgroup.com - and the relevant
                            Assurance or Escrow manager will guide you from
                            there.
                        </p>
                    )
                }
            },
            {
                key: 'stuck',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'stuck-header',
                    title: "My referral hasn't moved on for weeks, what can I do?"
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'stuck-body',
                    children: (
                        <p className={paragraphStyles}>
                            Contact the assigned Salesperson, which is shown in
                            the App, in the first instance. Failing that, reach
                            out to referrals@nccgroup.com
                        </p>
                    )
                }
            },
            {
                key: 'escrow',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'escrow-header',
                    title: 'How can I discuss Software Resilience (Escrow) with a client?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'escrow-body',
                    children: (
                        <p className={paragraphStyles}>
                            See the list of applicable Software Resilience
                            scenario's{' '}
                            <a
                                target="_blank"
                                referrerPolicy="no-referrer"
                                href="https://nccgrp.sharepoint.com/sites/pre-sales/Playbooks/Forms/AllItems.aspx?id=%2Fsites%2Fpre%2Dsales%2FPlaybooks%2FSoftware%20Resilience%2FSoftware%20Resilience%5FRecommendation%20Scenarios%2Epdf&parent=%2Fsites%2Fpre%2Dsales%2FPlaybooks%2FSoftware%20Resilience&p=true&originalPath=aHR0cHM6Ly9uY2NncnAuc2hhcmVwb2ludC5jb20vOmI6L3MvcHJlLXNhbGVzL0VUYUJINUtrSkFoQnVTaDdNUzAtTFlVQnhHYVVyZGgzZkxRbE1wRnJsbkROWmc_cnRpbWU9RjhnTHI4OXkyVWc"
                                className={linkStyles}
                            >
                                here
                            </a>{' '}
                            - if these scenarios result in an interested client
                            or opportunity please log them in the Referral App!
                        </p>
                    )
                }
            },
            {
                key: 'reward',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'reward-header',
                    title: 'My referral has been Won/Invoiced, when will I receive the cash reward?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'reward-body',
                    children: (
                        <p className={paragraphStyles}>
                            Rewards are paid monthly in payroll. Which payroll
                            month depends on when the opportunity gets marked as
                            invoiced and you receive the notification in the
                            app. If before the bonus cut-off date for that month
                            - which is normally around the middle of the month -
                            you will get it that month's pay. If its after the
                            cut-off date, the reward will roll into the
                            following month's payroll.
                        </p>
                    )
                }
            },
            {
                key: 'bug',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'bug-header',
                    title: "I've found a bug in the app, who can I contact?"
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'bug-body',
                    children: (
                        <p className={paragraphStyles}>
                            Email referral-ops@nccgroup.com detailing what
                            you've found, how it happened and all useful
                            information.
                        </p>
                    )
                }
            },
            {
                key: 'more',
                panelProps: commonPanelProps,
                headerProps: {
                    ...commonHeaderProps,
                    'data-cy': 'more-header',
                    title: 'How can I find out more about the Referral App?'
                },
                bodyProps: {
                    ...commonBodyProps,
                    'data-cy': 'more-body',
                    children: (
                        <p className={paragraphStyles}>
                            Check out the Referral App Knowledge Site via the
                            SharePoint tab.
                        </p>
                    )
                }
            }
        ],
        []
    );

    const bodyLayoutClasses = 'hidden md:block w-3/5 xl:text-fourteen';
    const footerSizeClasses = 'mt-0 md:mt-twenty xl:mt-thirty';

    return (
        <PageWrapper destination="/" header="Help &amp; FAQs">
            <div className="mt-5 flex gap-5 items-stretch flex-col-reverse md:flex-col xl:flex-row">
                <Panel className="flex-1">
                    <Panel.Header title="Frequently Asked Questions" />
                    <Panel.Body>
                        <PanelGroup
                            panels={frequentlyAskQuestions}
                            mobileLength={3}
                        />
                    </Panel.Body>
                </Panel>
                <div className="flex-1 flex flex-col gap-5">
                    <Panel className="relative overflow-hidden">
                        <BackgroundImage image={DesignTeam} />

                        <Panel.Header title="Referral App Sharepoint" />
                        <Panel.Body tailwindLayout={bodyLayoutClasses}>
                            Needing to refresh your knowledge on the referral
                            process? Wanting to check the valid referrals
                            criteria? Search our SharePoint site for all this
                            and more.
                        </Panel.Body>
                        <Panel.Footer tailwindSize={footerSizeClasses}>
                            <a
                                target="_blank"
                                referrerPolicy="no-referrer"
                                href="https://nccgrp.sharepoint.com/sites/ReferralApp"
                                data-cy="sharepoint"
                            >
                                <Button label="Sharepoint" />
                            </a>
                        </Panel.Footer>
                    </Panel>
                    <Panel className="relative overflow-hidden">
                        <BackgroundImage image={WorkTime} />

                        <Panel.Header title="Contact Support" />
                        <Panel.Body tailwindLayout={bodyLayoutClasses}>
                            Have a referral query and not sure who to contact?
                            Check our Contact Support page to point you in the
                            right direction.
                        </Panel.Body>
                        <Panel.Footer tailwindSize={footerSizeClasses}>
                            <a
                                target="_blank"
                                referrerPolicy="no-referrer"
                                href="https://nccgrp.sharepoint.com/sites/ReferralApp/SitePages/Contact-Support.aspx"
                                data-cy="contact-support"
                            >
                                <Button label="Contact support" />
                            </a>
                        </Panel.Footer>
                    </Panel>
                    <Panel className="relative overflow-hidden">
                        <BackgroundImage image={Collaboration} />

                        <Panel.Header title="Our Global Portfolio" />
                        <Panel.Body tailwindLayout={bodyLayoutClasses}>
                            Want to know more about our products and services?
                            Visit Our Global Portfolio to explore what the group
                            has to offer, and learn how you can provide more
                            value for your clients in every engagement.
                        </Panel.Body>
                        <Panel.Footer tailwindSize={footerSizeClasses}>
                            <a
                                target="_blank"
                                referrerPolicy="no-referrer"
                                href="https://nccgrp.sharepoint.com/sites/GlobalPortfolio"
                                data-cy="gpa-sharepoint"
                            >
                                <Button label="GPA Sharepoint" />
                            </a>
                        </Panel.Footer>
                    </Panel>
                </div>
            </div>
        </PageWrapper>
    );
}

export default FaqsPage;
