import React from 'react';
import { Button } from '../Button/Button';
import { useHistory } from 'react-router-dom';
import Panel from '../../uiLib/panel';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';

function HelpCard(props: TailwindProps) {
    const history = useHistory();

    const handleClick = () => {
        history.push('/faqs');
    };

    return (
        <Panel
            data-cy="helpcard-background"
            data-testid="helpcard-background"
            {...props}
        >
            <Panel.Header
                icon={
                    <img
                        src="/images/question-mark.svg"
                        alt="My Rewards"
                        style={{ filter: 'invert(100%)' }}
                        data-cy="help-center-icon"
                        data-testid="help-center-icon"
                    />
                }
                title="Help Center"
                tailwindSize=""
                data-cy="help-center-header"
            >
                <Button
                    label="Help &amp; FAQs"
                    variant="outlined"
                    onClick={handleClick}
                    dataCy="help-center-button"
                    dataTestId="help-center-button"
                />
            </Panel.Header>
        </Panel>
    );
}

export default HelpCard;
