import React from 'react';
import useTailwind from '../../uiLib/tailwind/useTailwind';

export default function ProgressBar({ stamp, tailwindLayout }) {
    const cssClass = useTailwind({
        tailwindLayout
    });
    const str = stamp?.toString();

    return (
        <div className={cssClass}>
            <h4 className="sr-only">Progress Bar</h4>
            <div className="flex justify-center halfscreen:mb-1">
                <p
                    className="text-twentyfour font-bold text-icongreen dark:text-darkmodetext"
                    data-cy="bar-progress-number"
                    data-testid="bar-progress-number"
                >
                    {str + '/10'}
                </p>
            </div>
            <div className="">
                <div
                    className="bg-barborder rounded-full overflow-hidden dark:bg-darkprogressbg"
                    data-cy="bar-progress-inner-container"
                    data-testid="bar-progress-inner-container"
                >
                    <div
                        className="h-xlbar bg-icongreen rounded-full border border-solid border-barborder dark:border-darkprogressbg"
                        style={{ width: `${stamp * 10}%` }}
                        data-cy="bar-progress-bar"
                        data-testid="bar-progress-bar"
                    />
                </div>
            </div>
        </div>
    );
}
