import React from 'react';
import currencySymbol from '../../utilities/currencySym';
import cashConverter from '../../utilities/moneyFormatter';

interface AmountContainerProps {
    amount?: number | string;
    currency?: string | undefined;
}

const AmountContainer = ({ amount = 0, currency }: AmountContainerProps) => {
    return (
        <div className="flex flex-col justify-center">
            <div className="text-center md:text-fortytwo xl:text-sixty text-twentyfour text-icongreen tracking-wide font-bold dark:text-darkmodetext">
                {currencySymbol(currency) + cashConverter(amount)}
            </div>
        </div>
    );
};

export default AmountContainer;
