import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReferralDetailsComponent from '../../components/ReferralDetailsPage/ReferralDetailsComponent';
import FullscreenSpinner from '../../ui/FullscreenSpinner/FullscreenSpinner';
import { useHttpContext } from '../../context/HttpContext';
import useRequest from '../../hooks/useRequest';
import { UserContext } from '../../context/UserContext';
import currencySymbol from '../../utilities/currencySym';

const ReferralDetailsContainer = () => {
    const { fetchAReferral } = useHttpContext();
    const params = useParams();
    const { user } = useContext(UserContext);
    const currSymbol = currencySymbol(user?.currency);

    const {
        send: requestReferral,
        loading: referralLoading,
        response: referralResponse
    } = useRequest(fetchAReferral);

    useEffect(() => {
        requestReferral([params]);
    }, [params]);

    return referralLoading ? (
        <FullscreenSpinner />
    ) : (
        <ReferralDetailsComponent
            referral={referralResponse}
            currSymbol={currSymbol}
        />
    );
};

export default ReferralDetailsContainer;
