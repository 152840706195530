import React from 'react';
import GradientBackground from '../../ui/GradientBackground/GradientBackground';
import Logo from '../../ui/Logo/Logo';
import { Button } from '../../ui/Button/Button';
import { ReactComponent as Icon } from './real-time-sync.svg';

function LoginComponent({ loginHandler }) {
    const sectionCSSClasses = 'flex flex-col justify-center ';

    return (
        <GradientBackground isLoginPage>
            <div className="h-full w-full flex flex-col place-content-between xl:grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-0 pb-24 xl:content-center">
                <div
                    className={`${sectionCSSClasses} items-center xl:items-start md:px-40 gap-10 h-2/3 xl:h-full z-10`}
                >
                    <Logo
                        isResponsive
                        dataCy="login-logo"
                        dataTestId="login-logo"
                    />
                    <p className="text-white leading-tight text-center tracking-wide sm:tracking-normal sm:text-sixteen md:text-thirty md:font-extralight md:text-center xl:font-extralight xl:text-twentyeight xl:text-left xl:tracking-normal filter drop-shadow-lg px-2 xl:px-0">
                        Raise new sales opportunities and get rewarded for
                        successful leads.
                    </p>

                    <Button
                        label={'Login'}
                        dataCy="login-submit-button"
                        variant="secondary"
                        disabled={false}
                        onClick={loginHandler}
                        dataTestId="login-submit-button"
                    />
                </div>

                <div
                    className={`${sectionCSSClasses} items-center h-1/3 md:h-2/3 xl:h-full px-10 xl:px-0`}
                >
                    <Icon
                        alt="Login"
                        className="max-h-96 flex-shrink xl:h-auto"
                    />
                </div>
            </div>
        </GradientBackground>
    );
}

export default LoginComponent;
