import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { useFormContext, Controller } from 'react-hook-form';

import Select from 'react-select';
import { custom } from './selectTheme';
import FormField from '../FormField/FormField';

export default function FormSelect({
    label,
    id,
    tooltip,
    component,
    handleHelp,
    name,
    placeholder,
    opts,
    dataCy,
    dataTestId,
    isRequired
}) {
    const { theme } = useContext(ThemeContext);
    const {
        control,
        formState: { errors }
    } = useFormContext();

    const err = errors?.[name]?.value?.message || errors?.[name]?.message;

    return (
        <FormField
            label={label}
            id={id}
            error={err}
            tooltip={tooltip}
            component={component}
            handleHelp={handleHelp}
            isSelect={true}
            dataCy={dataCy}
            isRequired={isRequired}
        >
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Select
                        styles={custom(theme)}
                        options={opts}
                        name={name}
                        {...field}
                        id={id}
                        isClearable
                        placeholder={placeholder}
                        data-cy={dataCy + 'form-select'}
                        data-testid={dataTestId + 'form-select'}
                    />
                )}
            />
        </FormField>
    );
}
