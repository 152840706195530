import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../Button/Button';
import Panel from '../../uiLib/panel';

interface LeadCardProps {
    theme: 'light' | 'dark';
}

function LeadCard({ theme }: LeadCardProps) {
    const { push } = useHistory();

    const viewAll = useCallback(() => {
        push('/create-new-lead');
    }, [push]);

    return (
        <Panel
            className="relative bg-cover sm:bg-no-repeat"
            style={{
                backgroundImage:
                    theme === 'light'
                        ? 'url(/images/bglead.png)'
                        : 'url(/images/bgleaddark.png)'
            }}
            data-cy="leadcard-background"
            data-testid="leadcard-background"
        >
            <img
                src="/images/preferences-popup.svg"
                alt="Latest referral"
                className="absolute top-1/2 transform -translate-y-1/2 h-smlead md:h-mdlead lg:h-lglead xl:h-xllead right-4 sm:right-8"
                data-cy="leadcard-img"
                data-testid="leadcard-img"
            />
            <Panel.Header title="Register a New Referral" data-cy="leadcard" />
            <Panel.Body
                className="hidden md:block md:w-leadbox"
                data-cy="leadcard-cta"
            >
                Raise new sales opportunities and get rewarded for successful
                leads.
            </Panel.Body>
            <Panel.Footer tailwindSize="md:mt-8 lg:mt-twenty xl:mt-thirty">
                <Button
                    label="Register a referral"
                    variant="primary"
                    dataCy="leadcard-button"
                    dataTestId="leadcard-button"
                    onClick={viewAll}
                />
            </Panel.Footer>
        </Panel>
    );
}

export default LeadCard;
export type { LeadCardProps };
