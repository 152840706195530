import React from 'react';

export default function Welcome({ user }) {
    return (
        <div className="flex flex-row">
            <p
                className="font-sans text-fontprimary font-bold text-twentyone md:text-thirty dark:text-darkmodetext"
                data-cy="welcome-message"
            >
                Welcome {user?.first_name}
            </p>
        </div>
    );
}
