import { useContext } from 'react';
import { PanelContext } from './PanelContext';

function usePanel() {
    const context = useContext(PanelContext);

    if (!context) throw new Error('Panel context provider needed.');

    return context;
}

export default usePanel;
