import React from 'react';
import './gradientLoader.css';

const GradientLoader = () => {
    return (
        <div className="bg-white rounded-lg absolute top-0 left-0 right-0 bottom-0">
            <div className={`css-selector h-full w-full rounded-lg`} />
        </div>
    );
};

export default GradientLoader;
