import { ExclamationCircleIcon } from '@heroicons/react/solid';
import Tooltip from '../../Tooltip/Tooltip';
import CharCounter from '../../CharCounter/CharCounter';
import Asterisk from '../../../uiLib/asterisk/asterisk';

function FormField({
    label,
    id,
    error,
    children,
    tooltip,
    component,
    handleHelp,
    dataCy,
    dataTestId,
    isSelect,
    hasCount,
    count,
    maxChars,
    isRequired
}) {
    return (
        <div className="max-w-8xl mb-ten md:mt-0">
            <div className="flex place-content-between">
                <div>
                    <label
                        htmlFor={id}
                        className="font-sans text-fourteen md:text-sixteen block font-light text-black-100 mt-0 dark:text-darkmodetext"
                        data-cy={dataCy + 'form-label'}
                        data-testid={dataTestId + 'form-label'}
                    >
                        {label}
                        {isRequired ? <Asterisk /> : null}
                    </label>
                </div>
                <div className="flex flex-row justify-center items-end gap-2">
                    {!hasCount ? null : (
                        <CharCounter charCount={count} maxLength={maxChars} />
                    )}
                    {tooltip ? (
                        <Tooltip
                            tooltip={tooltip}
                            handleHelp={handleHelp}
                            component={component}
                        />
                    ) : null}
                </div>
            </div>
            <div className="mt-1 relative rounded-xl mb-0 xl:mt-2">
                {children}
                {error && !isSelect ? (
                    <div
                        className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        data-cy={dataCy + 'error-icon'}
                        data-testid={dataTestId + 'error-icon'}
                    >
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                ) : null}
            </div>
            {error ? (
                <div className="bg-red-100 p-2 rounded-md mt-1">
                    <p
                        className="text-red-700 font-light text-twelve text-sans"
                        id={id + '-error'}
                        data-cy={dataCy + 'error-message'}
                        data-testid={dataTestId + 'error-message'}
                    >
                        {error}
                    </p>
                </div>
            ) : null}
        </div>
    );
}

export default FormField;
