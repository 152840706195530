interface DividerProps {
    className?: string;
}

const Divider = ({ className }: DividerProps) => {
    let cssClass =
        'border bg-bordergrey border-solid border-bordergrey dark:border-darkgreyborder dark:bg-darkgreyborder';

    if (className) {
        cssClass += ` ${className}`;
    }

    return <div className={cssClass} />;
};

export default Divider;
