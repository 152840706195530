import ArrowIcon from './ArrowIcon';

export default function BackArrow({ destination, header }) {
    return (
        <div
            className="flex mt-4 items-start w-full max-w-4xl"
            data-cy="back-arrow-container"
            data-testid="back-arrow-container"
        >
            <div className="flex items-center">
                <div className="pr-2">
                    <ArrowIcon destination={destination} />
                </div>
                <div>
                    <p
                        className="font-sans text-fontprimary text-twentyone font-bold md:text-thirty dark:text-darkmodetext"
                        data-cy="back-arrow-header"
                        data-testid="back-arrow-header"
                    >
                        {header}
                    </p>
                </div>
            </div>
        </div>
    );
}
