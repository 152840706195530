const NoReferralsSection = () => {
    return (
        <div
            className="text-center text-lg xl:text-twentyfour"
            data-cy="latest-referral-nodata-text"
        >
            You don't currently have any referrals to display
        </div>
    );
};

export default NoReferralsSection;
