import React from 'react';
import { ReactComponent as FixingImage } from './maintenance.svg';

function MaintenanceMode(): React.ReactElement {
    return (
        <div className="h-screen overflow-y-hidden flex flex-col justify-center items-center xl:h-x gap-5 md:gap-10 px-4 -mt-10 xl:mt-0 mx-8 md:mx-20 xl:mx-40">
            <h1
                className="font-sans font-bold text-eighteen md:text-twentyfour lg:text-twentysix dark:text-darkmodetext"
                data-cy="maintenance-mode-title"
                data-testid="maintenance-mode-title"
            >
                The Referral App is currently under maintenance
            </h1>
            <FixingImage
                className="h-48 md:h-threefifty "
                data-cy="maintenance-mode-image"
                data-testid="maintenance-mode-image"
            />
            <h2
                className="font-sans text-eighteen md:text-twentytwo lg:text-twentyfour dark:text-darkmodetext"
                data-cy="maintenance-mode-text"
                data-testid="maintenance-mode-text"
            >
                If you have a referral you need to raise urgently please contact
                your{' '}
                <span className="font-medium italic">Referral Sales Admin</span>
                . For anything else or if you’re sure who your admin is, please
                contact{' '}
                <span className="font-medium underline">Referral-Ops</span>.
            </h2>
        </div>
    );
}

export default MaintenanceMode;
