import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

interface InputProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    dataCy?: string;
    dataTestId?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ dataCy = '', dataTestId = '', ...restProps }, ref) => {
        return (
            <input
                ref={ref}
                className="block w-full pl-3 pr-10 h-thirtysix font-normal text-black font-sans  rounded-crsradius border-solid border-inputborder border text-sixteen bg-white focus:outline-none focus:border-none dark:bg-darkappbg dark:text-darkmodetext dark:border-darkgreyborder fill-blue-500"
                aria-invalid="true"
                aria-describedby="user-input"
                data-cy={dataCy + 'form-input'}
                data-testid={dataTestId + 'form-input'}
                {...restProps}
            />
        );
    }
);

export default Input;
