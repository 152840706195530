import React from 'react';
import User from '../../core/api/models/user';
import Panel from '../../uiLib/panel';
import AmountContainer from './AmountContainer';

interface EarningsCardProps {
    awarded?: string | number;
    user?: User;
}

const EarningsCard = ({ awarded, user }: EarningsCardProps) => {
    return (
        <Panel variant="primary" tailwindLayout="flex-2 xl:flex xl:flex-col">
            <Panel.Header title="Referral Earnings" />
            <Panel variant="secondary" className="flex-2 justify-center">
                <Panel.Body tailwindLayout="flex xl:place-content-around items-center justify-center xl:gap-4">
                    <AmountContainer
                        amount={awarded}
                        currency={user?.currency}
                    />
                    <img
                        src="/images/gifts.svg"
                        alt="My Rewards"
                        className="hidden xl:block xl:h-36"
                        data-cy="my-rewards-image"
                        data-testid="my-rewards-image"
                    />
                </Panel.Body>
            </Panel>
        </Panel>
    );
};

export default EarningsCard;
