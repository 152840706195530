import { useMemo } from 'react';
import { Referral } from '../../core/api/models/referral';
import PanelGroup, { PanelGroupPanel } from '../../uiLib/panelGroup/PanelGroup';
import { TailwindProps } from '../../uiLib/tailwind/useTailwind';
import cashAward from '../../utilities/cashAward';
import MobileReferralDetailsPanel from './MobileReferralDetailsPanel';

interface MobileReferralInformationCardsProps extends TailwindProps {
    referral: Referral | undefined;
    currSymbol: string;
}

const MobileReferralInformationCards = ({
    referral,
    currSymbol,
    ...restProps
}: MobileReferralInformationCardsProps) => {
    const cashAwardData = cashAward(
        currSymbol,
        referral?.state,
        referral?.full_award
    );

    const salesforcePanelArray = [
        {
            label: 'Salesforce ID',
            value: `${referral?.sf_lead_id}`,
            testTag: 'salesforce-id'
        },
        {
            label: 'Assigned salesperson',
            value: `${referral?.sf_lead_owner}`
        },
        {
            label: 'Closed reason',
            value: `${referral?.closed_reason}`
        }
    ];

    if (referral?.other_closed_reason) {
        salesforcePanelArray.push({
            label: 'Other closed reason',
            value: `${referral?.other_closed_reason}`
        });
    }

    const referralInfo = useMemo<PanelGroupPanel[]>(
        () => [
            {
                key: 'salesforce-id',
                panelProps: {
                    variant: 'primary',
                    'data-cy': 'mobile-salesforce-card',
                    ...restProps
                },
                headerProps: {
                    title: 'Salesforce Information',
                    'data-cy': 'mobile-salesforce-header',
                    identifier: 'mobile-salesforce'
                },
                bodyProps: {
                    children: (
                        <MobileReferralDetailsPanel
                            title="salesforce"
                            dataCy="salesforce-card"
                            dataTestId="salesforce-card"
                            sections={salesforcePanelArray}
                        />
                    ),
                    tailwindLayout: 'flex flex-col gap-4'
                }
            },
            {
                key: 'reward-id',
                panelProps: {
                    variant: 'primary',
                    'data-cy': 'mobile-reward-card',
                    ...restProps
                },
                headerProps: {
                    title: 'Reward Information',
                    'data-cy': 'mobile-reward-header',
                    identifier: 'mobile-reward'
                },
                bodyProps: {
                    children: (
                        <MobileReferralDetailsPanel
                            title="rewards"
                            dataCy="rewards-card"
                            dataTestId="rewards-card"
                            sections={[
                                {
                                    label: 'Stamp',
                                    value: `${
                                        referral?.stamp_awarded
                                            ? 'Rewarded'
                                            : 'Not rewarded'
                                    }`,
                                    testTag: 'stamp'
                                },
                                {
                                    label: 'Invoiced amount',
                                    value: `${currSymbol}${referral?.invoice_amount}`,
                                    testTag: 'invoiced-amount'
                                },
                                {
                                    label: 'Cash award',
                                    value: `${cashAwardData}`,
                                    testTag: 'cash-award'
                                }
                            ]}
                        />
                    ),
                    tailwindLayout: 'flex flex-col gap-4'
                }
            },
            {
                key: 'customer-id',
                panelProps: {
                    variant: 'primary',
                    'data-cy': 'mobile-customer-card',
                    ...restProps
                },
                headerProps: {
                    title: 'Customer Details',
                    'data-cy': 'mobile-customer-header',
                    identifier: 'mobile-customer'
                },
                bodyProps: {
                    children: (
                        <MobileReferralDetailsPanel
                            title="customer"
                            dataCy="customer-card"
                            dataTestId="customer-card"
                            sections={[
                                {
                                    label: 'Full name',
                                    value: `${referral?.contact_title} ${referral?.contact_first_name} ${referral?.contact_last_name}`,
                                    testTag: 'full-name'
                                },
                                {
                                    label: 'Email address',
                                    value: `${referral?.contact_email}`,
                                    testTag: 'email-address'
                                },
                                {
                                    label: 'Phone number',
                                    value: `${referral?.contact_number}`,
                                    testTag: 'phone-number'
                                }
                            ]}
                        />
                    ),
                    tailwindLayout: 'flex flex-col gap-4'
                }
            },
            {
                key: 'referral-id',
                panelProps: {
                    variant: 'primary',
                    'data-cy': 'mobile-referral-card',
                    ...restProps
                },
                headerProps: {
                    title: 'Referral Information',
                    'data-cy': 'mobile-referral-header',
                    identifier: 'mobile-referral'
                },
                bodyProps: {
                    children: (
                        <MobileReferralDetailsPanel
                            title="referral"
                            dataCy="referral-card"
                            dataTestId="referral-card"
                            sections={[
                                {
                                    label: 'Submitted to',
                                    value: `${referral?.division}`,
                                    testTag: 'submitted-to'
                                },
                                {
                                    label: 'Product of interest',
                                    value: `${referral?.product_of_interest}`,
                                    testTag: 'poi'
                                },
                                {
                                    label: 'Additional information',
                                    value: `${referral?.additional_information}`,
                                    testTag: 'additional-information'
                                }
                            ]}
                        />
                    ),
                    tailwindLayout: 'flex flex-col gap-4'
                }
            }
        ],
        [referral]
    );

    return <PanelGroup panels={referralInfo} />;
};

export default MobileReferralInformationCards;
