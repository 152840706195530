import React from 'react';
import Panel from '../../uiLib/panel';
import Pagination from '../Pagination/Pagination';
import RewardDetailCard from '../RewardDetailCard/RewardDetailCard';
import Spinner from '../spinner/Spinner';
import NoReferralsCard from './NoReferralsCard';

const RewardsPanel = ({
    fullAwardData,
    currentPage,
    totalPages,
    onPageChange,
    user,
    fullAwardLoading
}) => {
    const { currency } = user;
    return (
        <Panel tailwindLayout="flex flex-col gap-4 relative">
            <Panel.Header title="Individual Referral Rewards" />
            {fullAwardLoading && (
                <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
                    <Spinner size={12} />
                </div>
            )}
            {!fullAwardData?.results?.length ? (
                <NoReferralsCard />
            ) : (
                fullAwardData?.results.map((reward) => {
                    return (
                        <RewardDetailCard
                            reward={reward}
                            key={reward?.id}
                            currency={currency}
                        />
                    );
                })
            )}
            {fullAwardData?.results.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    totalCount={fullAwardData?.count}
                    pageSize={3}
                />
            )}
        </Panel>
    );
};

export default RewardsPanel;
