export default function cashAward(curr, state, fullAward) {
    if (state === 'Won') return 'Pending';
    if (state === 'Invoiced') return `${curr}${fullAward.amount}`;
    if (state === 'Lost' || state === 'Disqualified') return 'No reward';
    if (
        state === 'New' ||
        state === 'In Progress' ||
        state === 'Nurture' ||
        state === 'Qualified' ||
        state === 'Proposed'
    )
        return `${curr}0.00`;
}
