import React from 'react';

const CharCounter = ({ charCount, maxLength }) => {
    return (
        <div>
            <p className="text-textprimary font-sans text-twelve dark:text-darkmodetext">
                {charCount || 0} / {maxLength}
            </p>
        </div>
    );
};

export default CharCounter;
